import React from "react";
import PropTypes from "prop-types";
import CImage from "./CImage";
import Container from "./Container";
import Cta from "./Cta";
import Youtube from "./YouTube";

const Tagline = ({
  text1,
  text2,
  textSeparatorImage,
  language,
  cta,
  title,
  price,
  description,
  video,
}) => {
  return (
    <>
      <div data-el="0" className="relative">
        <img
          src="/bubbles/bubbles-1.png"
          className="absolute top-0 -left-64 md:left-0 md:-top-24"
        />
        <img
          src="/bubbles/bubbles-2.png"
          className="absolute top-25p -right-64 md:right-0"
        />
        <Container className="relative">
          <div className="relative flex items-center py-24 md:py-0 md:h-65vh">
            <div className="flex w-full justify-center flex-col xs:flex-row gap-4 relative ">
              <div className="flex">
                <div className="relative mr-2 text-right font-logoFont">
                  <span className="select-none text-logo md:text-logo-big logo t-wght2 hover:logo-ready text-blue-sea xl:text-white">
                    F
                  </span>
                  <h1 className="hidden opacity-0 text-blue-sea xl:text-white">
                    Formentera 2.0
                  </h1>
                </div>
                <div className=" ml-2 text-xl leading-tight md:text-2xl md:mt-3 text-blue-sea xl:text-white">
                  <span dangerouslySetInnerHTML={{ __html: text1[language] }} />
                  <CImage className="my-4" {...textSeparatorImage} />
                  <div
                    className="contains-html"
                    dangerouslySetInnerHTML={{ __html: text2[language] }}
                  />
                </div>
              </div>
              {/* <div className="relative mt-24 ">
                <img src="/price.svg" className="w-full h-full" />
                <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center px-16 text-center">
                  <div>
                    <div
                      className="text-2xl text-e22-gray font-secondary wght-700"
                      dangerouslySetInnerHTML={{
                        __html: title[language],
                      }}
                    />
                    <span className="block text-6xl text-e22-gray font-secondary wght-500 md:pb-2">
                      {price}
                    </span>
                  </div>
                </div>
                <div className="absolute z-10 top-0 -right-4 xs:-right-16">
                  {cta && (
                    <Cta
                      language={language}
                      title={cta.title}
                      target="_blank"
                      className="bg-e23-lightBlue text-e23-black scale07 l:scale08 md:scale1"
                      href={cta.href[language]}
                    >
                      <span className="block px-8 text-2xl leading-tight text-center">
                        {cta.title[language]}
                      </span>
                    </Cta>
                  )}
                </div>
              </div> */}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

Tagline.propTypes = {
  language: PropTypes.string.isRequired,
  text1: PropTypes.shape(),
  text2: PropTypes.shape(),
  textSeparatorImage: PropTypes.shape(),
  cta: PropTypes.array,
  video: PropTypes.string,
};

Tagline.defaultProps = {};

export default Tagline;
