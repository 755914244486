import React from "react"
import PropTypes from "prop-types"

const Wrapper = ({
  className,
  children,
  style,
}) => {
  return (
    <div
      className={`${className || ''} w-full`}
      style={style}
    >
        {children}
    </div>
  )
}

Wrapper.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
}

Wrapper.defaultProps = {
  className: '',
  children: null,
  style: {},
}


export default Wrapper