import React from "react"
import PropTypes from "prop-types"
import Container from './Container'
import { Link } from "gatsby"

const Breadcrumb = ({
  urls,
}) => {
  return (
    <div className="mt-16 md:mt-32 text-e22-gray">
      <Container className="flex justify-end md:w-8/12">
        {urls.map((item, index) => (
          <div key={item.link}>
            <Link className="hover:underline" to={item.link}>{item.title}</Link>
            {index < urls.length - 1 && <span className="px-2">&lt;</span>}
          </div>
        ))}
      </Container>
    </div>
  )
}

Breadcrumb.propTypes = {
  urls: PropTypes.array,
}

Breadcrumb.defaultProps = {
}

export default Breadcrumb
