import React from "react"
import PropTypes from "prop-types"
import Container from './Container'

const YouTube = ({
  id,
}) => {
  return (
    <div className="relative">
      <Container>
        <div className="md:flex md:justify-center mb-8">
          <div className="w-full md:w-8/12">
            <div
              className="relative w-full h-0"
              style={{ paddingTop: '56.25%' }}
            >
              <iframe
                className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
                src={`https://www.youtube.com/embed/${id}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </Container>
  </div>
  )
}

YouTube.propTypes = {
  id: PropTypes.string,
}

YouTube.defaultProps = {}

export default YouTube