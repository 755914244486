import React from "react"
import PropTypes from "prop-types"

const Container = ({
  className,
  children,
}) => {
  return (
    <div
      className={`${className || ''} w-90p max-w-1480px mx-auto`}
    >{children}</div>
  )
}

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

Container.defaultProps = {
}

export default Container
