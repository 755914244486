import React from "react";
import PropTypes from "prop-types";
import Hero from "./Hero";
import Container from "./Container";

const Program = ({ language, hero, items }) => {
  return (
    <div className="relative">
      <Container className="relative">
        <div className="mt-20 mb-24 md:mt-24 md:mb-12 md:flex md:justify-center">
          <div className="text-e22-gray full md:mb-5 md:w-8/12">
            <Hero language={language} {...hero} />
            <div className="mt-24">
              {items.map((item) => (
                <div key={item.date[language]}>
                  <h3 className="mt-8 mb-10 text-e22-gray md:mt-4 md:text-xl font-secondary wght-700">
                    {item.date[language]}
                  </h3>
                  <div className="flex flex-col flex-wrap md:flex-row">
                    {item.subitems.map((subitem) => (
                      <div
                        key={subitem.id}
                        className="flex w-full text-e22-gray md:w-1/2 md:-mx-3"
                      >
                        <p className="w-1/4 font-bold md:ml-3">
                          {subitem.hour}
                        </p>
                        <div className="w-3/4 font-bold leading-tight md:mr-3">
                          <h4 className="mb-6">{subitem.name[language]}</h4>
                          {subitem.description && (
                            <p className="mb-6 font-normal">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: subitem.description[language],
                                }}
                              />
                            </p>
                          )}
                          {subitem.speakers &&
                            subitem.speakers.map((speaker) => {
                              return (
                                <div key={speaker.name}>
                                  <p>{speaker.name}</p>
                                  <p className="font-normal">
                                    {speaker.description[language]}
                                  </p>
                                  <a
                                    className="font-normal hover:underline"
                                    href={speaker.href}
                                  >
                                    {speaker.hreftext}
                                  </a>
                                </div>
                              );
                            })}
                          <img className="my-6 md:my-10" src="/wave.svg" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

Program.propTypes = {
  language: PropTypes.string,
  hero: PropTypes.shape(),
  items: PropTypes.array,
};

Program.defaultProps = {};

export default Program;
