import React from "react"
import PropTypes from "prop-types"
import Container from './Container'

const Title = ({
  language, title, text
}) => {
  return (
      <Container>
        <div 
          data-el="5"
          className="md:flex md:justify-center">
          <div className={`w-full mt-16 md:w-8/12 ${text ? "md:mb-16" : ""}`}>
            <div className="md:w-4/5 md:pr-4">
              <h1 className="text-e22-gray mb-16 text-3xl wght-700 font-secondary leading-tight md:text-5xl md:mb-4.5rem">{title[language]}</h1>
              {text ? (
                <div
                  className="text-e22-gray font-primary contains-html md:mb-8"
                  dangerouslySetInnerHTML={{ __html: text[language] }}
                />
              ) : ""}
            </div>
          </div>
        </div>
      </Container>
  )
}

Title.propTypes = {
  language: PropTypes.string,
  title: PropTypes.object,
  text: PropTypes.object,
}

Title.defaultProps = {
}

export default Title