import React, { useState, useEffect, useRef } from "react"
// import PropTypes from "prop-types"
import throttle from '../lib/throttle'
import useDidMount from '../lib/useDidMount'

class Ball {
  constructor(paper, config, r, p, v) {
    this.paper = paper
    this.radius = r
    this.point = p
    this.vector = v
    this.maxVec = 0.2
    this.numSegment = Math.floor(r / 3 + 2)
    this.boundOffset = []
    this.boundOffsetBuff = []
    this.sidePoints = []
    this.path = new paper.Path({
      fillColor: config.color,
    })

    for (let i = 0; i < this.numSegment; i ++) {
      this.boundOffset.push(this.radius)
      this.boundOffsetBuff.push(this.radius)
      this.path.add(new paper.Point())
      this.sidePoints.push(new paper.Point({
        angle: 360 / this.numSegment * i,
        length: 1
      }))
    }
  }

  remove() {
    this.path.remove()
  }

  iterate() {
		this.checkBorders()
		if (this.vector.length > this.maxVec) {
      this.vector.length = this.maxVec
    }
    this.point = new this.paper.Point(this.point.x + this.vector.x, this.point.y + this.vector.y)
		this.updateShape()
	}

	checkBorders() {
		var size = this.paper.view.size
		if (this.point.x < -this.radius)
			this.point.x = size.width + this.radius
		if (this.point.x > size.width + this.radius)
			this.point.x = -this.radius
		if (this.point.y < -this.radius)
			this.point.y = size.height + this.radius
		if (this.point.y > size.height + this.radius)
			this.point.y = -this.radius
	}

	updateShape() {
		var segments = this.path.segments
		for (let i = 0; i < this.numSegment; i ++)
			segments[i].point = this.getSidePoint(i)

		this.path.smooth()
		for (let i = 0; i < this.numSegment; i ++) {
			if (this.boundOffset[i] < this.radius / 4) {
        this.boundOffset[i] = this.radius / 4
      }
			var next = (i + 1) % this.numSegment
			var prev = (i > 0) ? i - 1 : this.numSegment - 1
			var offset = this.boundOffset[i]
			offset += (this.radius - offset) / 15
			offset += ((this.boundOffset[next] + this.boundOffset[prev]) / 2 - offset) / 3
			this.boundOffsetBuff[i] = this.boundOffset[i] = offset
		}
	}

	react(b) {
		var dist = this.point.getDistance(b.point)
		if (dist < this.radius + b.radius && dist != 0) {
      var overlap = this.radius + b.radius - dist
      var newPoint = new this.paper.Point(this.point.x - b.point.x, this.point.y - b.point.y)
      var direc = newPoint.normalize(overlap * 0.25)
      this.vector = new this.paper.Point(this.vector.x + direc.x, this.vector.y + direc.y)
      b.vector = new this.paper.Point(b.vector.x - direc.x, b.vector.y - direc.y)

			this.calcBounds(b)
			b.calcBounds(this)
			this.updateBounds()
			b.updateBounds()
		}
	}

	getBoundOffset(b) {
		var diff = new this.paper.Point(this.point.x - b.x, this.point.y - b.y)
		var angle = (diff.angle + 180) % 360
		return this.boundOffset[Math.floor(angle / 360 * this.boundOffset.length)]
	}

	calcBounds(b) {
		for (let i = 0; i < this.numSegment; i ++) {
      var tpCoords = this.getSidePoint(i)
      var tp = new this.paper.Point(tpCoords.x, tpCoords.y)
			var bLen = b.getBoundOffset(tp)
			var td = tp.getDistance(b.point)
			if (td < bLen) {
				this.boundOffsetBuff[i] -= (bLen  - td) / 2
			}
		}
	}

	getSidePoint(index) {
    var point = new this.paper.Point(this.sidePoints[index].x * this.boundOffset[index], this.sidePoints[index].y * this.boundOffset[index])
		return new this.paper.Point(this.point.x + point.x, this.point.y + point.y)
	}

	updateBounds() {
		for (let i = 0; i < this.numSegment; i ++)
			this.boundOffset[i] = this.boundOffsetBuff[i]
	}
}

const Bubbles = (name) => {
  const [isBubbly, setIsBubbly] = useState(false)
  const didMount = useDidMount()
  const main = useRef(null)

  useEffect(() => {
    if (didMount && isBubbly === false) setIsBubbly(true)
    return () => setIsBubbly(false)
  }, [didMount])

  useEffect(() => {
    isBubbly && beBubbly()
  }, [isBubbly])

  const beBubbly = () => {
    const paper = typeof window !== `undefined` ? require("paper") : null
    if (!paper) return

    var balls = []
    var numBalls = name === 'index' ? 10 : 4

    const onResize = () => {
      if (!main.current) return
      paper.setup(main.current)
      paper.view.setViewSize(new paper.Size(main.current.offsetWidth, main.current.offsetHeight))
      if (balls.length > 0) {
        balls.forEach(ball => ball.remove())
      }
      balls = []
      drawBalls()
    }

    const drawBalls = () => {
      const ballsConfig = [
        {
          color: '#3366FF',
          radius: 2.4,
        },
        {
          color: '#FFCC57',
          radius: 3,
        },
        {
          color: '#FFCC57',
          radius: 2,
        },
        {
          color: '#3366FF',
          radius: 4,
        },
        {
          color: '#3366FF',
          radius: 2.5,
        },
        {
          color: '#3366FF',
          radius: 3,
        },
        {
          color: '#3366FF',
          radius: 5,
        },
        {
          color: '#FFCC57',
          radius: 2.8,
        },
        {
          color: '#3366FF',
          radius: 3,
        },
        {
          color: '#FFCC57',
          radius: 2.8,
        },
      ]

      for (let i = 0; i < numBalls; i++) {

        const x = i % 2 === 0 ? paper.view.size.width * 0.15 : paper.view.size.width * 0.85
        const y = paper.view.size.height / numBalls * (i + 1)

        var position = new paper.Point(x, y)
        var vector = new paper.Point({
          angle: 360 * Math.random(),
          length: Math.random() * 10
        })

        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        const isMobile = width < 768

        var radius = isMobile ? paper.view.size.width : paper.view.size.width / (ballsConfig[i].radius * 0.9)
        if (radius > height / 2) radius = height / 2

        if (name !== 'index') {
          radius = radius * 0.7
        }

        balls.push(new Ball(paper, ballsConfig[i], radius, position, vector))
      }
    }

    function onFrame() {
      for (let i = 0; i < balls.length - 1; i++) {
        for (var j = i + 1; j < balls.length; j++) {
          balls[i].react(balls[j])
        }
      }
      for (let i = 0, l = balls.length; i < l; i++) {
        balls[i].iterate()
      }
    }

    onResize()

    const timeout = setTimeout(() => {
      onResize()
      clearTimeout(timeout)
    }, 300)

    paper.view.onResize = onResize
    paper.view.onFrame = onFrame

    const onResizeEvent = throttle(onResize, 100)
    window.addEventListener('resize', onResizeEvent.bind(this))
  }

  return (
    <canvas
      ref={main}
      className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
    />
  )
}

Bubbles.propTypes = {
}

Bubbles.defaultProps = {
}

export default Bubbles
