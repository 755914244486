import React from "react";
import PropTypes from "prop-types";

const clipPath = `M73.0543478,128.184786 C112.786803,121.838276 143,102.740086 143,70.4230743 C143,38.1060629 125.902174,6.88519138 74.6086957,0.606744457 C23.3152174,-5.67170247 0,38.1060629 0,70.4230743 C0,102.740086 33.3218931,134.531296 73.0543478,128.184786 Z`;
const smallClipPath = `M 36.5272 64.0924 C 56.3934 60.9191 71.5 51.37 71.5 35.2115 C 71.5 19.053 62.9511 3.4426 37.3043 0.3034 C 11.6576 -2.8359 0 19.053 0 35.2115 C 0 51.37 16.6609 67.2656 36.5272 64.0924 Z`;

const Cta = (props) => {
  const theProps = { ...props };
  delete theProps.callback;
  if (props.adform) {
    delete theProps.adform;
  }
  delete theProps.svgName;
  delete theProps.smaller;
  const height = props.smaller ? 66 : 129;
  const width = props.smaller ? 72 : 143;

  const content = (
    <span className={`block ${props.spanClassName}`}>
      {props.children ||
        (typeof props.title === "string"
          ? props.title
          : props.title[props.language])}
      <span className="absolute bottom-0 right-0 w-0 h-0 overflow-hidden pointer-events-none -z-10">
        <svg>
          <defs>
            <clipPath id={`${props.svgName}`}>
              <path d={props.smaller ? smallClipPath : clipPath} />
            </clipPath>
          </defs>
        </svg>
      </span>
    </span>
  );

  if (theProps.noButton) {
    return (
      <div
        {...theProps}
        className={`font-secondary ${props.colorText} flex items-center justify-center flex-col ${props.className}`}
        style={{
          clipPath: `url(#${props.svgName})`,
          WebkitClipPath: `url(#${props.svgName})`,
          height: height,
          width: width,
        }}
      >
        {content}
      </div>
    );
  }

  const isMobile = screen.width < 769;

  console.log(isMobile);
  if (isMobile) {
    return (
      <a
        {...theProps}
        className={`cursor-pointer font-secondary ${props.colorText} flex items-center justify-center flex-col ${props.className} t-wght-transform cta hover:cta-ready hover:scale095 rounded-full`}
        style={{
          height: height,
          width: width,
        }}
        onClick={props.adform ? props.adform : props.callback}
      >
        {content}
      </a>
    );
  } else {
    return (
      <a
        {...theProps}
        className={`cursor-pointer font-secondary ${props.colorText} flex items-center justify-center flex-col ${props.className} t-wght-transform cta hover:cta-ready hover:scale095`}
        style={{
          clipPath: `url(#${props.svgName})`,
          WebkitClipPath: `url(#${props.svgName})`,
          height: height,
          width: width,
        }}
        onClick={props.adform ? props.adform : props.callback}
      >
        {content}
      </a>
    );
  }
};

Cta.propTypes = {
  language: PropTypes.string.isRequired,
  title: PropTypes.any,
  spanClassName: PropTypes.string,
  className: PropTypes.string,
  colorText: PropTypes.string,
  children: PropTypes.node,
  svgName: PropTypes.string,
  smaller: PropTypes.bool,
  adform: PropTypes.any,
  callback: PropTypes.func,
};

Cta.defaultProps = {
  svgName: "cta",
  smaller: false,
  colorText: "text-white",
};

export default Cta;
