import React from "react";
import PropTypes from "prop-types";
import clips from "../lib/clipsSchedule.js";

const Hero = ({ language, title, description, image }) => {
  return (
    <div className="flex flex-col mb-6 md:flex-row md:mb-0">
      <div className="w-full md:w-2/5">
        <h1 className="mb-16 pt-10 text-3xl text-e22-gray md:text-5xl md:mr-3 font-secondary wght-700 leading-tight">
          {title[language]}
        </h1>
        <p className="leading-tight">
          <span dangerouslySetInnerHTML={{ __html: description[language] }} />
        </p>
      </div>
      <div className="flex justify-start w-120p md:mt-24 md:w-3/5 md:justify-end">
        <div className="absolute bottom-0 right-0 w-0 h-0 overflow-hidden pointer-events-none -z-10">
          <svg>
            <defs>
              <clipPath id={`clipProgram`}>
                <path d={clips[0].path} />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          className={`overflow-visible relative scale08 md:scale14`}
          style={{
            height: clips[0].h,
            width: clips[0].w,
            clipPath: `url(#clipProgram)`,
            WebkitClipPath: `url(#clipProgram)`,
          }}
        >
          <div
            className="absolute top-0 bottom-0 left-0 right-0 bg-center bg-no-repeat bg-cover hover:scale11 t-transform"
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  language: PropTypes.string,
  items: PropTypes.array,
  title: PropTypes.shape(),
  description: PropTypes.shape(),
  image: PropTypes.string,
};

Hero.defaultProps = {};

export default Hero;
