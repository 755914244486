import React from "react";
import PropTypes from "prop-types";
import Cta from "./Cta";
import clips from "../lib/clips.js";
import Container from "./Container";

const urlSpeaker = {
  es: "ponente",
  ca: "ponent",
  en: "speaker",
};

const Bubble = ({ language, item, index, clip, right, left }) => (
  <div
    className={`relative md:b${index}`}
    style={{
      maxWidth: clip.w,
      maxHeight: clip.h,
    }}
  >
    <Cta
      title={{ es: item.name, en: item.name, ca: item.name }}
      language={language}
      href={`/${language}/${urlSpeaker[language]}/${item.slug}`}
      className={`absolute text-white bottom-0 z-10 bg-e23-blue scale08 md:scale1 ${
        index % 2 === 0 ? "right-0" : "left-0"
      } ${right ? "md:right-0" : "md:right-auto"} ${
        left ? "md:left-0" : "md:left-auto"
      }`}
    >
      <span className="block text-sm text-center font-regular">
        {item.name}
      </span>
      <span className="block text-sm font-bold text-center">
        {item.company}
      </span>
    </Cta>
    <div
      className={`overflow-hidden relative scale08 md:scale1 ${
        index % 2 === 0 ? "-right-4 md:right-0" : "-left-4 md:left-0"
      }`}
      style={{
        height: clip.h,
        width: clip.w,
        clipPath: `url(#clip${index})`,
        WebkitClipPath: `url(#clip${index})`,
      }}
    >
      <div
        className="absolute top-0 bottom-0 left-0 right-0 bg-center bg-no-repeat bg-cover hover:scale11 t-transform"
        style={{
          backgroundImage: `url(${item.image})`,
        }}
      />
      <div className="absolute top-0 bottom-0 left-0 right-0 " />
    </div>
  </div>
);

Bubble.propTypes = {
  language: PropTypes.string.isRequired,
  item: PropTypes.shape(),
  index: PropTypes.number,
  clip: PropTypes.shape(),
  right: PropTypes.bool,
  left: PropTypes.bool,
};

const Speakers = ({ items, title, text, language }) => {
  return (
    <div data-el="2" className="relative">
      <img
        src="/bubbles/bubbles-6.png"
        className="absolute -bottom-64 -left-64 md:left-0"
      />
      <Container>
        <div
          data-section="speakers"
          className="py-24 md:py-0 md:my-48 md:pb-32 md:relative"
        >
          <div className="md:pb-16 md:flex md:justify-center lg:absolute md:top-0 md:left-0 md:right-0">
            <div className="md:w-8/12">
              <div className="text-e22-gray md:w-full lg:w-1/2">
                <h2 className="mb-4 text-2xl font-secondary wght-700">
                  {title[language]}
                </h2>
                <div
                  className="mb-4 contains-html"
                  dangerouslySetInnerHTML={{ __html: text[language] }}
                />
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 right-0 w-0 h-0 overflow-hidden pointer-events-none -z-10">
            {clips.map((clip, index) => (
              <svg key={index}>
                <defs>
                  <clipPath id={`clip${index}`}>
                    <path d={clip.path} />
                  </clipPath>
                </defs>
              </svg>
            ))}
          </div>
          <div className="md:flex md:justify-center z-50">
            <div className="md:grid md:grid-template-columns-9fr md:w-10/12 lg:w-8/12">
              <div className="flex items-center justify-start l:col-start-4 l:col-end-7 lg:col-start-7 lg:col-end-10 md:row-start-1 md:pr-12 md:-ml-12">
                <Bubble
                  right
                  item={items[0]}
                  clip={clips[0]}
                  index={0}
                  language={language}
                />
              </div>
              <div className="flex items-center justify-center l:row-start-2 l:col-start-2 l:col-end-5 lg:row-start-2 lg:col-start-2 lg:col-end-5 md:row-start-1">
                <Bubble
                  right
                  item={items[1]}
                  clip={clips[1]}
                  index={1}
                  language={language}
                />
              </div>
              <div className="flex items-center justify-start l:row-start-2 l:col-start-6 l:col-end-9 md:row-start-2 md:-ml-12">
                <Bubble
                  right
                  item={items[2]}
                  clip={clips[2]}
                  index={2}
                  language={language}
                />
              </div>
              <div className="flex items-center justify-end l:row-start-3 l:col-start-2 l:col-end-5 lg:col-start-1 lg:col-end-4 md:row-start-2">
                <Bubble
                  left
                  item={items[3]}
                  clip={clips[3]}
                  index={3}
                  language={language}
                />
              </div>
              <div className="flex items-center justify-start l:row-start-3 l:col-start-6 l:col-end-9 lg:row-start-3 lg:col-start-4 lg:col-end-7 md:row-start-3 md:-ml-12 lg:m-0">
                <Bubble
                  left
                  item={items[4]}
                  clip={clips[4]}
                  index={4}
                  language={language}
                />
              </div>
              <div className="flex items-center justify-center l:row-start-4 l:col-start-7 l:col-end-10 lg:col-start-9 lg:col-end-12 lg:-mt-24 md:row-start-3">
                <Bubble
                  left
                  item={items[5]}
                  clip={clips[5]}
                  index={5}
                  language={language}
                />
              </div>
              <div className="flex items-center justify-end l:row-start-4 l:col-start-3 l:col-end-6 l:m-6 lg:mt-16 lg:row-start-4 lg:col-start-1 lg:col-end-4 md:row-start-4 md:mt-24 md:mb-12 md:-ml-12 lg:m-0">
                <Bubble
                  right
                  item={items[6]}
                  clip={clips[6]}
                  index={6}
                  language={language}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

Speakers.propTypes = {
  items: PropTypes.array,
  title: PropTypes.shape(),
  text: PropTypes.shape(),
  language: PropTypes.string.isRequired,
};

Speakers.defaultProps = {};

export default Speakers;
