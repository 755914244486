const throttle = (func, limit) => {
  let inThrottle
  return () => {
    // eslint-disable-next-line no-undef
    const args = arguments
    const context = this
    if (!inThrottle) {
      func.apply(context, args)
      inThrottle = true
      setTimeout(() => inThrottle = false, limit)
    }
  }
}

export default throttle