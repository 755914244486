import React from "react";
import PropTypes from "prop-types";
import Cta from "./Cta";
import Container from "./Container";

const Tickets = ({ language, title, text, text2, items }) => {
  return (
    <>
      <div className="relative md:h-80vh text-e22-gray xl:mb-56 lg:m-0">
        <Container>
          <div className="py-24 md:py-0 md:my-64 md:relative">
            <div className="md:flex md:flex-col md:items-center">
              <div className="md:w-8/12">
                <h2 className="mb-4 text-2xl font-secondary wght-700">
                  {title[language]}
                </h2>
              </div>
              <div className="md:w-8/12 md:flex">
                <div className="md:w-1/2 md:pr-4">
                  <div
                    className="mb-4 contains-html"
                    dangerouslySetInnerHTML={{ __html: text[language] }}
                  />
                  <div
                    className="mb-4 text-sm italic contains-html"
                    dangerouslySetInnerHTML={{ __html: text2[language] }}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex md:justify-center">
              <div className="flex flex-col-reverse md:w-9/12 lg:w-8/12 md:flex md:flex-row md:justify-between md:items-start md:mt-24">
                <div className="relative">
                  <img src="/price1.svg" className="w-full h-full" />
                  <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center px-16 text-center">
                    <div>
                      <h3 className="text-xl font-secondary wght-700">
                        {items[0].title[language]}
                      </h3>
                      <span className="block text-4xl font-secondary wght-800 md:py-2">
                        {items[0].price}
                      </span>
                      <div
                        className="block text-sm contains-html"
                        dangerouslySetInnerHTML={{
                          __html: items[0].description[language],
                        }}
                      />
                    </div>
                  </div>
                  <Cta
                    language={language}
                    title={items[0].cta.title}
                    adform={() =>
                      window.adf &&
                      window.adf.ClickTrack(
                        this,
                        2323376,
                        "Click_ComprarAhora",
                        {}
                      )
                    }
                    target="_blank"
                    className="absolute z-10 bg-e23-blue -bottom-16 md:-bottom-16 -left-4 md:left-auto md:-right-8 scale08 md:scale1"
                    href={items[0].cta.href[language]}
                  >
                    <span className="block px-8 text-2xl leading-tight text-center">
                      {items[0].cta.title[language]}
                    </span>
                  </Cta>
                </div>
                <div className="relative mt-8 mb-16 md:my-0">
                  <img src="/price2.svg" className="w-full h-full" />
                  <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center px-16 text-center">
                    <div>
                      <div
                        className="text-xl text-e22-gray font-secondary wght-700"
                        dangerouslySetInnerHTML={{
                          __html: items[1].title[language],
                        }}
                      />
                      <span className="block text-6xl text-e22-gray font-secondary wght-800 md:pb-2">
                        {items[1].price}
                      </span>
                      <div
                        className="block text-e22-gray contains-html"
                        dangerouslySetInnerHTML={{
                          __html: items[1].description[language],
                        }}
                      />
                    </div>
                  </div>
                  <Cta
                    language={language}
                    adform={() =>
                      window.adf &&
                      window.adf.ClickTrack(
                        this,
                        2323376,
                        "Click_ComprarAhora",
                        {}
                      )
                    }
                    title={items[1].cta.title}
                    target="_blank"
                    className="absolute z-10 bg-e23-blue -bottom-12 md:-bottom-12 -right-4 scale08 md:scale1"
                    href={items[1].cta.href[language]}
                  >
                    <span className="block px-8 text-2xl leading-tight text-center">
                      {items[0].cta.title[language]}
                    </span>
                  </Cta>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

Tickets.propTypes = {
  language: PropTypes.string.isRequired,
  items: PropTypes.array,
  title: PropTypes.shape(),
  text: PropTypes.shape(),
  text2: PropTypes.shape(),
};

Tickets.defaultProps = {};

export default Tickets;
