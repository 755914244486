const clips = [
  {
    path: `M184.679912,312.177632 C283.594275,297.027186 356,245.262041 356,168.114586 C356,90.9671305 313.434783,16.4363898 185.73913,1.44842573 C58.0434783,-13.5395383 0,90.9671305 0,168.114586 C0,245.262041 85.7655488,327.328078 184.679912,312.177632 Z`,
    w: 356,
    h: 314,
  },
  {
    path: `M296.214128,501.074925 C454.866098,476.757012 571,393.669009 571,269.839972 C571,146.010936 502.728261,26.3819759 297.913043,2.32486169 C93.0978261,-21.7322526 0,146.010936 0,269.839972 C0,393.669009 137.562158,525.392838 296.214128,501.074925 Z`,
    w: 571,
    h: 504,
  },
]

export default clips