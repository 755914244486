const data = [
  {
    slug: "2024",
    hrefTo: {
      es: 'archivo/2024',
      ca: 'arxiu/2024',
      en: 'archive/2024',
    },
    title: {
      es: "Edición 2024",
      ca: "Edició 2024",
      en: "2024 Edition"
    },
    text: {
      es:
        `<ul>
          <li>Anaïs Pérez Figueras. Google</li>
          <li>Oscar Ordoñez. Caixabank</li>
          <li>Mª Jesús Espinosa de los Monteros. Prisa</li>
          <li>Sebastián Sánchez. Manifiesto</li>
          <li>Rosario Sierra. Linkedin</li>
          <li>Gonzalo Figari. Club de Creativos</li>
          <li>Sergio Méndez. Warner Music</li>
        </ul>`,
      ca: 
        `<ul>
          <li>Anaïs Pérez Figueras. Google</li>
          <li>Oscar Ordoñez. Caixabank</li>
          <li>Mª Jesús Espinosa de los Monteros. Prisa</li>
          <li>Sebastián Sánchez. Manifiesto</li>
          <li>Rosario Sierra. Linkedin</li>
          <li>Gonzalo Figari. Club de Creativos</li>
          <li>Sergio Méndez. Warner Music</li>
        </ul>`,
      en:
        `<ul>
          <li>Anaïs Pérez Figueras. Google</li>
          <li>Oscar Ordoñez. Caixabank</li>
          <li>Mª Jesús Espinosa de los Monteros. Prisa</li>
          <li>Sebastián Sánchez. Manifiesto</li>
          <li>Rosario Sierra. Linkedin</li>
          <li>Gonzalo Figari. Club de Creativos</li>
          <li>Sergio Méndez. Warner Music</li>
        </ul>`,
    },
    videos: [
      {id: "1", size: "large", vimeo: true, videoId: "981241334"},
      {id: "2", size: "small", vimeo: true, videoId: "953445773"},
      {id: "3", size: "medium", vimeo: true, videoId: "953454778"},
      {id: "4", size: "large", vimeo: true, videoId: "953455822"},
      {id: "5", size: "medium", vimeo: true, videoId: "953455962"},
      {id: "6", size: "small", vimeo: true, videoId: "953461921"},
      {id: "7", size: "large", vimeo: true, videoId: "953447049"},
      {id: "8", size: "small", vimeo: true, videoId: "981877769"},
    ],
    media: {
      title: {
        es: "Media",
        ca: "Media",
        en: "Media"
      },
      items: [
        {
          id: "1",
          url: "https://www.rtve.es/play/audios/mediterraneo/otro-ano-formentera-20-desconectar-para-conectar-03-03-24/15995363",
          image: "/media/2024/2024_1.jpeg",
          title: {
            es: "Otro año en Formentera 20 | Radio 3",
            ca: "Otro año en Formentera 20 | Radio 3",
            en: "Otro año en Formentera 20 | Radio 3"
          },
        },
        {
          id: "2",
          url: "https://www.rtve.es/play/audios/mediterraneo/19-05-24/16109365",
          image: "/media/2024/2024_2.jpeg",
          title: {
            es: "Mediterráneo - 19/05/24 | Radio 3",
            ca: "Mediterráneo - 19/05/24 | Radio 3",
            en: "Mediterráneo - 19/05/24 | Radio 3"
          },
        }
      ]
    }
  },
  {
    slug: "2022",
    hrefTo: {
      es: 'archivo/2022',
      ca: 'arxiu/2022',
      en: 'archive/2022',
    },
    title: {
      es: "Edición 2022",
      ca: "Edició 2022",
      en: "2022 Edition"
    },
    text: {
      es:
        `<ul>
          <li>Toni Segarra. Publicista</li>
          <li>Mª Jesús Espinosa de los Monteros. Directora General de PRISA Audio</li>
          <li>Emilio Pila. Director de Next, Twitter España</li>
          <li>Pepa Rojo. Creative Strategist, Meta</li>
          <li>Pablo Skaf. Director Partner Marketing Spain & Portugal Netflix</li>
          <li>Bruno Sellés. Fundador y director creativo de Vasava</li>
          <li>Ana Gómez. Head of Agencies Partnerships at TikTok</li>
          <li>Tomás Alonso. Head of Corporate Communications & Public Affairs, Samsung.</li>
          <li>Isabel Martínez. Directora Creativa Isabelita Virtual</li>
          <li>Javier Piedrahita. Director, MarketingDirecto.com</li>
        </ul>`,
      ca: 
        `<ul>
          <li>Toni Segarra. Publicista</li>
          <li>Mª Jesús Espinosa de los Monteros. Directora General de PRISA Audio</li>
          <li>Emilio Pila. Director de Next, Twitter España</li>
          <li>Pepa Rojo. Creative Strategist, Meta</li>
          <li>Pablo Skaf. Director Partner Marketing Spain & Portugal Netflix</li>
          <li>Bruno Sellés. Fundador y director creativo de Vasava</li>
          <li>Ana Gómez. Head of Agencies Partnerships at TikTok</li>
          <li>Tomás Alonso. Head of Corporate Communications & Public Affairs, Samsung.</li>
          <li>Isabel Martínez. Directora Creativa Isabelita Virtual</li>
          <li>Javier Piedrahita. Director, MarketingDirecto.com</li>
        </ul>`,
      en:
        `<ul>
          <li>Toni Segarra. Publicista</li>
          <li>Mª Jesús Espinosa de los Monteros. Directora General de PRISA Audio</li>
          <li>Emilio Pila. Director de Next, Twitter España</li>
          <li>Pepa Rojo. Creative Strategist, Meta</li>
          <li>Pablo Skaf. Director Partner Marketing Spain & Portugal Netflix</li>
          <li>Bruno Sellés. Fundador y director creativo de Vasava</li>
          <li>Ana Gómez. Head of Agencies Partnerships at TikTok</li>
          <li>Tomás Alonso. Head of Corporate Communications & Public Affairs, Samsung.</li>
          <li>Isabel Martínez. Directora Creativa Isabelita Virtual</li>
          <li>Javier Piedrahita. Director, MarketingDirecto.com</li>
        </ul>`,
    },
      videos: [
      {id: "1", size: "large", vimeo: true, videoId: "720579971"},
      {id: "2", size: "small", vimeo: true, videoId: "720582183"},
      {id: "3", size: "medium", vimeo: true, videoId: "720581734"},
      {id: "4", size: "large", vimeo: true, videoId: "720581386"},
      {id: "5", size: "medium", vimeo: true, videoId: "722454164"},
      {id: "6", size: "small", vimeo: true, videoId: "720581841"},
      {id: "7", size: "large", vimeo: true, videoId: "720581305"},
      {id: "8", size: "small", vimeo: true, videoId: "720581126"},
      {id: "9", size: "medium", vimeo: true, videoId: "722454179"},
      {id: "10", size: "large", vimeo: true, videoId: "720581468"},
      {id: "11", size: "large", vimeo: true, videoId: "720581635"},
    ],
  },
  {
    slug: "2021",
    hrefTo: {
      es: 'archivo/2021',
      ca: 'arxiu/2021',
      en: 'archive/2021',
    },
    title: {
      es: "Edición 2021",
      ca: "Edició 2021",
      en: "2021 Edition"
    },
    text: {
      es:
        `<ul>
          <li>Mónica Moro. Directora Gral. Creativa, McCann España</li>
          <li>Miquel Martí. CEO, Tech Barcelona</li>
          <li>Elena Bule. Dircom, Twitter España</li>
          <li>Isabel Martínez. Dtra.Creativa, Isabelitavirtual</li>
          <li>Patrícia Reis. VP Marketing, HBO</li>
          <li>Soraya Castellanos. Spain Operations Lead, TikTok</li>
          <li>Mar Abad. Fundadora, El Extraordinario</li>
          <li>Carlos Fernández Guerra. Dtor.Digital & Social Media, Iberdrola</li>
          <li>Javier Piedrahita. Director, MarketingDirecto.com</li>
        </ul>`,
      ca: 
        `<ul>
          <li>Mónica Moro. Directora Gral. Creativa, McCann España</li>
          <li>Miquel Martí. CEO, Tech Barcelona</li>
          <li>Elena Bule. Dircom, Twitter España</li>
          <li>Isabel Martínez. Dtra.Creativa, Isabelitavirtual</li>
          <li>Patrícia Reis. VP Marketing, HBO</li>
          <li>Soraya Castellanos. Spain Operations Lead, TikTok</li>
          <li>Mar Abad. Fundadora, El Extraordinario</li>
          <li>Carlos Fernández Guerra. Dtor.Digital & Social Media, Iberdrola</li>
          <li>Javier Piedrahita. Director, MarketingDirecto.com</li>
        </ul>`,
      en:
        `<ul>
          <li>Mónica Moro. Directora Gral. Creativa, McCann España</li>
          <li>Miquel Martí. CEO, Tech Barcelona</li>
          <li>Elena Bule. Dircom, Twitter España</li>
          <li>Isabel Martínez. Dtra.Creativa, Isabelitavirtual</li>
          <li>Patrícia Reis. VP Marketing, HBO</li>
          <li>Soraya Castellanos. Spain Operations Lead, TikTok</li>
          <li>Mar Abad. Fundadora, El Extraordinario</li>
          <li>Carlos Fernández Guerra. Dtor.Digital & Social Media, Iberdrola</li>
          <li>Javier Piedrahita. Director, MarketingDirecto.com</li>
        </ul>`,
    },
    videos: [
      {id: "1", size: "large", vimeo: true, videoId: "574911480"},
      {id: "2", size: "small", vimeo: true, videoId: "571130227"},
      {id: "3", size: "medium", vimeo: true, videoId: "571130362"},
      {id: "4", size: "large", vimeo: true, videoId: "571130518"},
      {id: "5", size: "medium", vimeo: true, videoId: "574392175"},
      {id: "6", size: "small", vimeo: true, videoId: "575705088"},
      {id: "7", size: "large", vimeo: true, videoId: "574391851"},
      {id: "8", size: "small", vimeo: true, videoId: "574391718"},
      {id: "9", size: "medium", vimeo: true, videoId: "571130050"},
      {id: "10", size: "large", vimeo: true, videoId: "574391586"},
    ],
  },
  {
    slug: "2020",
    hrefTo: {
      es: 'archivo/2020',
      ca: 'arxiu/2020',
      en: 'archive/2020',
    },
    title: {
      es: "Edición 2020",
      ca: "Edició 2020",
      en: "2020 Edition"
    },
    text: {
      es:
        `<ul>
          <li>Javier Pagán. Country Head Spain & Portugal, Twitter</li>
          <li>Bruno Sellés. Fundador y Dtor. Creativo de  Vasava</li>
          <li>Marilín Gonzalo. Responsable Digital, Newtral</li>
          <li>Phil Gonzalez. Fundador Igers</li>
          <li>Juan Baixeras. VP Country Manager, Audible (Amazon)</li>
          <li>Cristina Ribas. Dtra. Comunicación Digital, Ajuntament de Barcelona</li>
          <li>Ricard Castellet. CCO Barcelona Tech City</li>
          <li>Aurora Hidalgo. Dtra. Creativa, Sra.Rushmore</li>
        </ul>`,
      ca: 
        `<ul>
          <li>Javier Pagán. Country Head Spain & Portugal, Twitter</li>
          <li>Bruno Sellés. Dtor. Creatiu i fundador de Vasava</li>
          <li>Marilín Gonzalo. Responsable Digital, Newtral</li>
          <li>Phil Gonzalez. Fundador Igers</li>
          <li>Juan Baixeras. Country Manager d'Audible (Amazon)</li>
          <li>Cristina Ribas. Dtra. Comunicació Digital de Ajuntament de Barcelona</li>
          <li>Ricard Castellet. CCO Barcelona Tech City</li>
          <li>Aurora Hidalgo. Dtra. Creativa, Sra.Rushmore</li>
        </ul>`,
      en:
        `<ul>
          <li>Javier Pagán. Country Head Spain & Portugal, Twitter</li>
          <li>Bruno Sellés. Creative Dtor. Vasava founder</li>
          <li>Marilín Gonzalo. Newtral Digital Manager</li>
          <li>Phil Gonzalez. Igers founder</li>
          <li>Juan Baixeras. VP Country Manager of Audible (Amazon)</li>
          <li>Cristina Ribas. Dtra. Barcelona City Council Digital Communication</li>
          <li>Ricard Castellet. CCO of Barcelona Tech City</li>
          <li>Aurora Hidalgo. Creative dtor. at Sra.Rushmore</li>
        </ul>`,
    },
    videos: [
      {id: "1", size: "large", vimeo: true, videoId: "490675047"},
      {id: "2", size: "small", vimeo: true, videoId: "490675107"},
      {id: "3", size: "medium", vimeo: true, videoId: "490674931"},
      {id: "4", size: "large", vimeo: true, videoId: "490675065"},
      {id: "5", size: "medium", vimeo: true, videoId: "490675141"},
      {id: "6", size: "small", vimeo: true, videoId: "490674908"},
      {id: "7", size: "large", vimeo: true, videoId: "490674993"},
      {id: "8", size: "small", vimeo: true, videoId: "490674984"},
      {id: "9", size: "medium", vimeo: true, videoId: "490674903"}
    ],
  },
  {
    slug: "2019",
    hrefTo: {
      es: 'archivo/2019',
      ca: 'arxiu/2019',
      en: 'archive/2019',
    },
    title: {
      es: "Edición 2019",
      ca: "Edició 2019",
      en: "2019 Edition"
    },
    text: {
      es:
        `<ul>
          <li>Anaïs Pérez Figueras. Directora de Comunicación, Google Iberia</li>
          <li>Macarena Rey. CEO, Shine Iberia</li>
          <li>Vincent Sourdeau. Director General, Disney Channel</li>
          <li>Tomás Fdo. Flores. Director, Radio-3</li>
          <li>Ricard Robles. Director, Sónar</li>
          <li>Enric Jové. CEO Mccann Barcelona & CIO Mccann Spain</li>
          <li>Oscar Romagosa. Productor Ejecutivo, Canada.</li>
          <li>Ana Gea. Fundadora de Graffica.</li>
          <li>Víctor Palau. Fundador de Graffica.</li>
        </ul>`,
      ca: 
        `<ul>
          <li>Anaïs Pérez Figueras. Directora de Comunicació, Google Ibèria</li>
          <li>Macarena Rei. CEO, Shine Ibèria</li>
          <li>Vincent Sourdeau. Director General, Disney Channel</li>
          <li>Tomás Fdo. Flores. Director, Radi-3</li>
          <li>Ricard Robles. Director, Sónar</li>
          <li>Enric Jové. CEO Mccann Barcelona & CIO Mccann Spain</li>
          <li>Oscar Romagosa. Productor Executiu, Canada.</li>
          <li>Ana Gea. Fundadora de Graffica.</li>
          <li>Víctor Palau. Fundador de Graffica.</li>
        </ul>`,
      en:
        `<ul>
          <li> Anaïs Pérez Figueras. Communication Director, Google Iberia</li>
          <li> Macarena Rey. CEO, Shine Iberia</li>
          <li> Vincent Sourdeau. CEO, Disney Channel</li>
          <li> Tomás Fdo. Flores. Director, Radio-3</li>
          <li> Ricard Robles. Director, Sónar </li>
          <li> Enric Jové. CEO Mccann Barcelona & CIO Mccann Spain </li>
          <li> Oscar Romagosa. Executive Producer, Canada. </li>
          <li> Ana Gea. Founder of Graffica. </li>
          <li> Víctor Palau. Founder of Graffica. </li>
        </ul>`,
    },
    videos: [
      {id: "1", size: "large", videoId: "06xJ1Dz1rA8"},
      {id: "2", size: "small", videoId: "35AILvtJs-c"},
      {id: "3", size: "medium", videoId: "CjdmW4TLknA"},
      {id: "4", size: "large", videoId: "NrYnHg6vo-E"},
      {id: "5", size: "medium", videoId: "CRloD6p04cY"},
      {id: "6", size: "small", videoId: "9ZTZ49K6Bts"},
      {id: "7", size: "large", videoId: "l0ufHnJjiLI"},
      {id: "8", size: "small", videoId: "87Yt4Sfs7K0"},
      {id: "9", size: "medium", videoId: "Eg-xP0Onjec"}
    ],
    media: {
      title: {
        es: "Media",
        ca: "Media",
        en: "Media"
      },
      items: [
        {
          id: "1",
          url: "https://www.ccma.cat/audio/embed/1038352/",
          image: "/media/2019/2019_1.png",
          title: {
            es: "Programa completo de Popap / Catalunya Radio (03/05/2019)",
            ca: "Programa completo de Popap / Catalunya Radio (03/05/2019)",
            en: "Programa completo de Popap / Catalunya Radio (03/05/2019)"
          },
        },
        {
          id: "2",
          url: "https://www.youtube.com/embed/UYihg-P9lDY",
          image: "/media/2019/2019_2.png",
          title: {
            es: "IB3 TV / Formentera 2.0 - 2019",
            ca: "IB3 TV / Formentera 2.0 - 2019",
            en: "IB3 TV / Formentera 2.0 - 2019"
          },
        }
      ]
    }
  },
  {
    slug: "2018",
    hrefTo: {
      es: 'archivo/2018',
      ca: 'arxiu/2018',
      en: 'archive/2018',
    },
    title: {
      es: "Edición 2018",
      ca: "Edició 2018",
      en: "2018 Edition"
    },
    text: {
      es:
        `<ul>
          <li>Sarah Harmon. Country Manager, Linkedin Iberia</li>
          <li>Álvaro Morales. Media & Innovation Manager, Carat</li>
          <li>Gloria Molins. Global Experiences Manager, Airbnb</li>
          <li>Lluís Alsina. Media & Innovation Manager, Gestmusic Endemol</li>
          <li>Toni Sellés. Fundador de Vasava</li>
          <li>Covadonga Noguerol. Responsable de Medios, Estrella Galicia.</li>
          <li>Juan Baixeras. VP Negocio, Playground</li>
          <li>J.M Ganyet. Director de Mortensen.</li>
        </ul>`,
      ca: 
        `<ul>
          <li>Sarah Harmon. Country Manager, Linkedin Ibèria</li>
          <li>Álvaro Morales. Mitjana & Innovation Manager, Carat</li>
          <li>Gloria Molins. Global Experiences Manager, Airbnb</li>
          <li>Lluís Alsina. Mitjana & Innovation Manager, Gestmusic Endemol</li>
          <li>Toni Sellés. Fundador de Vasava</li>
          <li>Covadonga Noguerol. Responsable de Mitjans, Estrella Galícia.</li>
          <li>Juan Baixeras. VP Negoci, Playground.</li>
          <li>J.M Ganyet. Director de Mortensen.</li>
        </ul>`,
      en:
        `<ul>
          <li> Sarah Harmon. Country Manager, Linkedin Iberia </li>
          <li> Álvaro Morales. Media & Innovation Manager, Carat </li>
          <li> Gloria Molins. Global Experiences Manager, Airbnb </li>
          <li> Lluís Alsina. Media & Innovation Manager, Gestmusic Endemol </li>
          <li> Toni Sellés. Founder of Vasava </li>
          <li> Covadonga Noguerol. Head of Media, Estrella Galicia. </li>
          <li> Juan Baixeras. VP Business, Playground. </li>
          <li> J.M Ganyet. Director of Mortensen. </li>
        </ul>`,
    },
    videos: [
      {id: "1", size: "large", videoId: "0SFZYjBtPsE"},
      {id: "2", size: "small", videoId: "OdJtprfn9nA"},
      {id: "3", size: "medium", videoId: "GiDTO0Etsws"},
      {id: "4", size: "large", videoId: "VaPDBSBnApI"},
      {id: "5", size: "medium", videoId: "3Mel4T2n2ns"},
      {id: "6", size: "small", videoId: "RYM7A2oVl1E"},
      {id: "7", size: "large", videoId: "l6OVqHgofik"},
      {id: "8", size: "small", videoId: "-Olwp3vRzfg"},
      {id: "9", size: "medium", videoId: "DBDt4XJ2vqo"},
      {id: "10", size: "large", videoId: "mD7vBTUlNoE"}
    ],
    media: {
      title: {
        es: "Media",
        ca: "Media",
        en: "Media"
      },
      items: [
        {
          id: "1",
          url: "https://www.ccma.cat/audio/embed/1001587/",
          image: "/media/2018/2018_1.jpg",
          title: {
            es: "Programa completo de Popap / Catalunya Radio (04/05/2018)",
            ca: "Programa completo de Popap / Catalunya Radio (04/05/2018)",
            en: "Programa completo de Popap / Catalunya Radio (04/05/2018)"
          },
        },
        {
          id: "2",
          url: "https://www.ccma.cat/audio/embed/1001576/",
          image: "/media/2018/2018_2.jpg",
          title: {
            es: "Programa Popap / Catalunya Radio. Entrevista a Sarah Harmon (Linkedin Iberia)",
            ca: "Programa Popap / Catalunya Radio. Entrevista a Sarah Harmon (Linkedin Iberia)",
            en: "Programa Popap / Catalunya Radio. Entrevista a Sarah Harmon (Linkedin Iberia)"
          },
        },
        {
          id: "3",
          url: "https://www.ccma.cat/audio/embed/1001580/",
          image: "/media/2018/2018_3.jpg",
          title: {
            es: "Programa Popap / Catalunya Radio. Entrevista a Álvaro Morales (Carat)",
            ca: "Programa Popap / Catalunya Radio. Entrevista a Álvaro Morales (Carat)",
            en: "Programa Popap / Catalunya Radio. Entrevista a Álvaro Morales (Carat)"
          },
        },
        {
          id: "4",
          url: "http://www.ccma.cat/audio/embed/1001575/",
          image: "/media/2018/2018_4.jpg",
          title: {
            es: "Programa Popap / Catalunya Radio. Entrevista a Juan Baixeras (PlayGround)",
            ca: "Programa Popap / Catalunya Radio. Entrevista a Juan Baixeras (PlayGround)",
            en: "Programa Popap / Catalunya Radio. Entrevista a Juan Baixeras (PlayGround)"
          },
        },
        {
          id: "5",
          url: "https://www.youtube.com/embed/XjoyXBdTgr8",
          image: "/media/2018/2018_5.jpg",
          title: {
            es: "IB3 TV / Formentera 2.0. Entrevista a Lluís Alsina (Gestmusic)",
            ca: "IB3 TV / Formentera 2.0. Entrevista a Lluís Alsina (Gestmusic)",
            en: "IB3 TV / Formentera 2.0. Entrevista a Lluís Alsina (Gestmusic)"
          },
        },
        {
          id: "6",
          url: "http://rtve.es/a/4577241",
          image: "/media/2018/2018_6.jpg",
          title: {
            es: "Mediterraneo / Radio 3",
            ca: "Mediterraneo / Radio 3",
            en: "Mediterraneo / Radio 3"
          },
        },
        {
          id: "7",
          url: "https://www.rtve.es/alacarta/audios/preferencies/preferencies-5-marc-2018/4506798/",
          image: "/media/2018/2018_7.jpg",
          title: {
            es: "Preferències / Radio 4",
            ca: "Preferències / Radio 4",
            en: "Preferències / Radio 4"
          },
        },
      ]
    }
  },
  {
    slug: "2017",
    hrefTo: {
      es: 'archivo/2017',
      ca: 'arxiu/2017',
      en: 'archive/2017',
    },
    title: {
      es: "Edición 2017",
      ca: "Edició 2017",
      en: "2017 Edition"
    },
    text: {
      es:
        `<ul>
          <li>Ana Castro-Colas. Media & Connections Manager, Coca-Cola</li>
          <li>Carlos Fdez Guerra. Dtor Digital y Social Media de Iberdrola</li>
          <li>Bernardo Crespo. Líder Divisadero</li>
          <li>David Saura. Director Contenidos, F.C Barcelona</li>
          <li>Bruno Sellés. Director Creativo/ Co-Fundador de Vasava</li>
          <li>Phil Gonzalez. Fundador Instagramers.</li>
          <li>Águeda Bañón. Directora Comunicación, Ajuntament de Barcelona.</li>
          <li>Franc Aleu. Director Creativo</li>
        </ul>`,
      ca: 
        `<ul>
          <li>Ana Castro-Colas. Mitjana & Connections Manager, Coca-Cola</li>
          <li>Carlos Fdez Guerra. Dtor Digital i Mitjà social d'Iberdrola</li>
          <li>Bernardo Crespo. Líder Divisadero</li>
          <li>David Saura. Director Continguts, F.C Barcelona</li>
          <li>Bruno Sellés. Director Creatiu/ Co-Fundador de Vasava</li>
          <li>Phil Gonzalez. Fundador Instagramers.</li>
          <li>Águeda Bañón. Directora Comunicació, Ajuntament de Barcelona.</li>
          <li>Franc Aleu. Director Creatiu</li>
        </ul>`,
      en:
        `<ul>
          <li>Ana Castro-Colas. Media & Connections Manager, Coca-Cola</li>
          <li>Carlos Fdez Guerra. Digital Dtor and Social Media of Iberdrola</li>
          <li>Bernardo Crespo. Leader Divisadero</li>
          <li>David Saura. Contents Director, F.C Barcelona</li>
          <li>Bruno Sellés. Creative Director/ Vasava Co-Fundador</li>
          <li>Phil Gonzalez. Instagramers Founder.</li>
          <li>Águeda Bañón. Communication Director, Ajuntament de Barcelona.</li>
          <li>Franc Aleu. Director Creativo</li>
        </ul>`,
    },
    videos: [
      {id: "1", size: "large", videoId: "fJ-6Q6wS6JM"},
      {id: "2", size: "small", videoId: "mH-oRVwA3UQ"},
      {id: "3", size: "medium", videoId: "l91ePuDumLc"},
      {id: "4", size: "large", videoId: "Ty51pHe1FZk"},
      {id: "5", size: "medium", videoId: "2gaEKSh-deQ"},
      {id: "6", size: "small", videoId: "OVtT5avkqcQ"},
      {id: "7", size: "large", videoId: "j1WAKc6hk_0"},
      {id: "8", size: "small", videoId: "ceIpwv-l5AY"},
      {id: "9", size: "medium", videoId: "vekr8_SW7uw"},
      {id: "10", size: "large", videoId: "t0KbnROKbEY"}
    ],
    media: {
      title: {
        es: "Media",
        ca: "Media",
        en: "Media"
      },
      items: [
        {
          id: "1",
          url: "http://www.ccma.cat/audio/embed/961352/",
          image: "/media/2017/2017_1.jpg",
          title: {
            es: "Programa Popap / Catalunya Radio (04/05/2017)",
            ca: "Programa Popap / Catalunya Radio (04/05/2017)",
            en: "Programa Popap / Catalunya Radio (04/05/2017)"
          },
        },
        {
          id: "2",
          url: "https://cadenaser.com/emisora/2017/05/01/radio_ibiza/1493653812_373848.html?ssm=tw",
          image: "/media/2017/2017_2.jpg",
          title: {
            es: "Radio Ser Ibiza (04/05/2017)",
            ca: "Radio Ser Ibiza (04/05/2017)",
            en: "Radio Ser Ibiza (04/05/2017)"
          },
        },
        {
          id: "3",
          url: "http://rtve.es/a/3987084",
          image: "/media/2017/2017_3.jpg",
          title: {
            es: "Preferències / Radio 4 (19/04/2017)",
            ca: "Preferències / Radio 4 (19/04/2017)",
            en: "Preferències / Radio 4 (19/04/2017)"
          },
        },
        {
          id: "4",
          url: "https://www.rtve.es/alacarta/audios/mediterraneo/mediterraneo-formentera-20-talleres-islados-16-04-17/3981843/",
          image: "/media/2017/2017_4.jpg",
          title: {
            es: "Mediterraneo / Radio 3 (16/04/2017)",
            ca: "Mediterraneo / Radio 3 (16/04/2017)",
            en: "Mediterraneo / Radio 3 (16/04/2017)"
          },
        },
        {
          id: "5",
          url: "http://cadenaser.com/emisora/2017/04/11/radio_ibiza/1491913285_175818.html?ssm=tw",
          image: "/media/2017/2017_5.jpg",
          title: {
            es: "Radio Ser Ibiza (11/04/2017)",
            ca: "Radio Ser Ibiza (11/04/2017)",
            en: "Radio Ser Ibiza (11/04/2017)"
          },
        },
        {
          id: "6",
          url: "https://www.andorradifusio.ad/programes/becaris/becaris-02-de-marc-de-2017/formentera-20",
          image: "/media/2017/2017_6.jpg",
          title: {
            es: "Becaris / RTVA Andorra Difusió (02/03/2017)",
            ca: "Becaris / RTVA Andorra Difusió (02/03/2017)",
            en: "Becaris / RTVA Andorra Difusió (02/03/2017)"
          },
        }
      ]
    }
  },
  {
    slug: "2016",
    hrefTo: {
      es: 'archivo/2016',
      ca: 'arxiu/2016',
      en: 'archive/2016',
    },
    title: {
      es: "Edición 2016",
      ca: "Edició 2016",
      en: "2016 Edition"
    },
    text: {
      es:
        `<ul>
          <li>Genís Roca. Fundador de Roca Salvatella</li>
          <li>Carlos Fdez Guerra. Dtor Digital y Social Media, Iberdrola</li>
          <li>Tinet Rubira. Director, Gest Music Endemol</li>
          <li>Sofía Mayoral. Directora Marketing Digital, Banco Sabadell</li>
          <li>Toni Sellés. Fundador de Vasava</li>
          <li>Abraham Clotet. IT Digital Manager, F.C Barcelona</li>
          <li>Karma Peiró. Directora de Nació Digital</li>
          <li>Javier Piedrahíta. Fundador/Director MarketingDirecto.com</li>
        </ul>`,
      ca: 
        `<ul>
          <li>Genís Roca. Fundador de Roca Salvatella</li>
          <li>Carlos Fdez Guerra. Dtor Digital i Mitjà social, Iberdrola</li>
          <li>Tinet Rubira. Director, Gest Music Endemol</li>
          <li>Sofia Mayoral. Directora Màrqueting Digital, Banc Sabadell</li>
          <li>Toni Sellés. Fundador de Vasava</li>
          <li>Abraham Clotet. IT Digital Manager, F.C Barcelona</li>
          <li>Karma Peiró. Directora de Nació Digital</li>
          <li>Javier Piedrahíta. Fundador/Director Marketingdirecto.com</li>
        </ul>`,
      en:
        `<ul>
          <li> Genís Roca. Founder of Roca Salvatella </li>
          <li> Carlos Fdez Guerra. Digital Dtor and Social Media, Iberdrola </li>
          <li> Tinet Rubira. Director, Gest Music Endemol </li>
          <li> Sofía Mayoral. Digital Marketing Director, Banco Sabadell </li>
          <li> Toni Sellés. Founder of Vasava </li>
          <li> Abraham Clotet. IT Digital Manager, F.C Barcelona </li>
          <li> Karma Peiró. Director of Nació Digital </li>
          <li> Javier Piedrahíta. Founder / Director MarketingDirecto.com </li>
        </ul>`,
    },
    videos: [
      {id: "1", size: "large", videoId: "AJIYrIT7_U8"},
      {id: "2", size: "small", videoId: "RkZn_pDSxCA"},
      {id: "3", size: "medium", videoId: "6Sin95MzAqc"},
      {id: "4", size: "large", videoId: "w9JBeCcGOEM"},
      {id: "5", size: "medium", videoId: "YhbtftE8vrU"},
      {id: "6", size: "small", videoId: "MCLncFk3_cg"},
      {id: "7", size: "large", videoId: "Cl1juGETZPo"},
      {id: "8", size: "small", videoId: "VwSGIGqqniI"},
      {id: "9", size: "medium", videoId: "um8QU_btE4U"},
      {id: "10", size: "large", videoId: "3dUF4pi6FX8"}
    ],
    media: {
      title: {
        es: "Media",
        ca: "Media",
        en: "Media"
      },
      items: [
        {
          id: "1",
          url: "http://www.rtve.es/drmn/embed/video/3613885",
          image: "/media/2016/2016_1.jpg",
          title: {
            es: "Cámara abierta 2.0 / TVE",
            ca: "Cámara abierta 2.0 / TVE",
            en: "Cámara abierta 2.0 / TVE"
          },
        }
      ]
    }
  },
  {
    slug: "2015",
    hrefTo: {
      es: 'archivo/2015',
      ca: 'arxiu/2015',
      en: 'archive/2015',
    },
    title: {
      es: "Edición 2015",
      ca: "Edició 2015",
      en: "2015 Edition"
    },
    text: {
      es:
        `<ul>
          <li>Toni Sellés. Fundador de Vasava</li>
          <li>Ana Fernández. Directora de Comunicación y Social Media, Vueling</li>
          <li>Franc Carreras. Profesor Marketing, Esade</li>
          <li>Xavier Agulló. Director de Comunicación, GSR</li>
          <li>Rafael Martínez. Co-Director, Folch Studio</li>
          <li>Joandó Reverter. Director de Estrategia, Somos Digitales.</li>
          <li>Javier Piedrahíta. Fundador/Director MarketingDirecto.com</li>
        </ul>`,
      ca: 
        `<ul>
          <li>Toni Sellés. Fundador de Vasava</li>
          <li>Ana Fernández. Directora de Comunicació i Social Media, Vueling</li>
          <li>Franc Carreras. Professor Marketing, Esade</li>
          <li>Xavier Agulló. Director de Comunicació, GSR</li>
          <li>Rafael Martínez. Co-Director, Folch Studio</li>
          <li>Joandó Reverter. Director d'Estratègia, Somos Digitales.</li>
          <li>Javier Piedrahíta. Fundador/Director MarketingDirecto.com</li>
        </ul>`,
      en:
        `<ul>
          <li>Toni Sellés. Founder, Vasava</li>
          <li>Ana Fernández. Communication and Social Media Director, Vueling</li>
          <li>Franc Carreras. Marketing Professor, Esade</li>
          <li>Xavier Agulló. Communication Director, GSR</li>
          <li>Rafael Martínez. Co-Director, Folch Studio</li>
          <li>Joandó Reverter. Strategy Director, Somos Digitales.</li>
          <li>Javier Piedrahíta. Founder/Director MarketingDirecto.com</li>
        </ul>`,
    },
    videos: [
      {id: "1", size: "large", videoId: "qGmTtPx2nFA"},
      {id: "2", size: "small", videoId: "opWq2RHOHX4"},
      {id: "3", size: "medium", videoId: "sMlg4fKhxA4"},
      {id: "4", size: "large", videoId: "_opXWLfJmWM"},
      {id: "5", size: "medium", videoId: "3AR2QqK3aDY"},
      {id: "6", size: "small", videoId: "O7Z1GotpB7E"},
      {id: "7", size: "large", videoId: "wO4gUoiPywM"},
      {id: "8", size: "small", videoId: "MitYCdcsNRI"},
      {id: "9", size: "medium", videoId: "RidZbHSnVlo"},
    ]
  },
  {
    slug: "2014",
    hrefTo: {
      es: 'archivo/2014',
      ca: 'arxiu/2014',
      en: 'archive/2014',
    },
    title: {
      es: "Edición 2014",
      ca: "Edició 2014",
      en: "2014 Edition"
    },
    text: {
      es:
        `<p>
          Masterclass/Talleres:
        </p>
        <p>
          Nuevas Tecnologías & New Media<br>
          <strong>Enric Palau (Sónar Festival)</strong>
        </p>
        <p>
          Creatividad 360º<br>
          <strong>Toni Sellés (Vasava)</strong>
        </p>
        <p>
          Crowdfunding/Micromecenazgo<br>
          <strong>Susana Noguero y Olivier Schulbaum (Goteo)</strong>
        </p>
        <p>
          Televisión y redes sociales (Next TV)<br>
          <strong>Guille Cascante (Goroka)</strong>
          <strong>Xavier Fisa (Lavinia Interactiva)</strong>
        </p>
        <p>
          Comunicación en redes sociales/Community Manager<br>
          <strong>Karma Peiró</strong>
        </p>
        <p>  
          Streaming/Cómo crear tu propio canal de TV<br>
          <strong>Lucrecia Baquero</strong>
        </p>
        <p>
          <br>Dirección<br>
          <strong>Rosa Castells</strong>
        </p>`,
      ca:
        `<p>
          Masterclass/Tallers:
        </p>
        <p>
          Noves Tecnologies & New Media<br>
          <strong>Enric Palau (Sónar Festival)</strong>
        </p>
        <p>
          Creativitat 360º<br>
          <strong>Toni Sellés (Vasava)</strong>
        </p>
        <p>
          Crowdfunding/Micromecenatge<br>
          <strong>Susana Noguero i Olivier Schulbaum (Goteo)</strong>
        </p>
        <p>
          Televisió i xarxes socials (Next TV)<br>
          <strong>Guille Cascante (Goroka)</strong>
          <strong>Xavier Fisa (Lavinia Interactiva)</strong>
        </p>
        <p>
          Comunicació en xarxes socials/Community Manager<br>
          <strong>Karma Peiró</strong>
        </p>
        <p>  
          Streaming/Com crear el teu propi canal de TV<br>
          <strong>Lucrecia Baquero</strong>
        </p>
        <p>
          <br>Direcció<br>
          <strong>Rosa Castells</strong>
        </p>`,
      en:
        `<p>
          Masterclass/Workshops:
        </p>
        <p>
          New Technologies & New Media<br>
          <strong>Enric Palau (Sónar Festival)</strong>
        </p>
        <p>
          360º Creativity<br>
          <strong>Toni Sellés (Vasava)</strong>
        </p>
        <p>
          Crowdfunding<br>
          <strong>Susana Noguero and Olivier Schulbaum (Goteo)</strong>
        </p>
        <p>
          Television and social networks (Next TV)<br>
          <strong>Guille Cascante (Goroka)</strong>
          <strong>Xavier Fisa (Lavinia Interactiva)</strong>
        </p>
        <p>
          Communication in social networks/Community Manager<br>
          <strong>Karma Peiró</strong>
        </p>
        <p>  
          Streaming/How to create your own TV channel<br>
          <strong>Lucrecia Baquero</strong>
        </p>
        <p>
          <br>Management<br>
          <strong>Rosa Castells</strong>
        </p>`,
    }
  },
  {
    slug: "2013",
    hrefTo: {
      es: 'archivo/2013',
      ca: 'arxiu/2013',
      en: 'archive/2013',
    },
    title: {
      es: "Edición 2013",
      ca: "Edició 2013",
      en: "2013 Edition"
    },
    text: {
      es:
        `<p>
          Masterclass/Talleres:
        </p>
        <p>
          Comunicación en redes sociales/Community manager<br>
          <strong>Karma Peiró</strong>
        </p>
        <p>
          Dirección de fotografía en spots y video<br>
          <strong>Carles Mestres</strong>
        </p>
        <p>
          Crowdfunding/Micromecenazgo<br>
          <strong>Isona Passola</strong>
        </p>
        <p>
          Creatividad 360º<br>
          <strong>Josep M.Ambros</strong>
        </p>
        <p>
          Nuevas tecnologías, viejos géneros. El video en internet<br>
          <strong>Miguel Mellado</strong>
        </p>
        <p>  
          Streaming/Cómo crear tu propio canal de TV<br>
          <strong>Lucrecia Baquero</strong>
        </p>
        <p>
          <br>Dirección<br>
          <strong>Rosa Castells</strong>
        </p>`,
      ca:
        `<p>
          Masterclass/Tallers:
        </p>
        <p>
          Comunicació en xarxes socials/Community Manager<br>
          <strong>Karma Peiró</strong>
        </p>
        <p>
          Direcció de fotografia en spots i vídeo<br>
          <strong>Carles Mestres</strong>
        </p>
        <p>
          Crowdfunding/Micromecenatge<br>
          <strong>Isona Passola</strong>
        </p>
        <p>
          Creativitat 360º<br>
          <strong>Josep M.Ambros</strong>
        </p>
        <p>
          Noves tecnologies, vells gèneres. El vídeo en internet<br>
          <strong>Miguel Mellado</strong>
        </p>
        <p>  
          Streaming/Com crear el teu propi canal de TV<br>
          <strong>Lucrecia Baquero</strong>
        </p>
        <p>
          <br>Direcció<br>
          <strong>Rosa Castells</strong>
        </p>`,
      en:
        `<p>
          Masterclass/Workshops:
        </p>
        <p>
        Communication in social networks/Community Manager<br>
          <strong>Karma Peiró</strong>
        </p>
        <p>
          Photography direction on sopts and video<br>
          <strong>Carles Mestres</strong>
        </p>
        <p>
          Crowdfunding<br>
          <strong>Isona Passola</strong>
        </p>
        <p>
          360º Creativity<br>
          <strong>Josep M.Ambros</strong>
        </p>
        <p>
          New technologies, old genres. Video on the internet<br>
          <strong>Miguel Mellado</strong>
        </p>
        <p>  
          Streaming/How to create your own TV channel<br>
          <strong>Lucrecia Baquero</strong>
        </p>
        <p>
          <br>Management<br>
          <strong>Rosa Castells</strong>
        </p>`,
    }
  }
]

module.exports = data