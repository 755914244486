import React from "react"
import PropTypes from "prop-types"
import Cta from './Cta'
import Container from './Container'

const About = ({
  language,
  items,
  title,
  text,
  cta,
}) => {
  return (
    <>   
    <div 
      data-el="1"
      className="relative md:h-65vh"
      >
      <img src="/bubbles/bubbles-4.png" className="absolute top-0 -left-64 md:left-0 md:-top-64"/>
      <img src="/bubbles/bubbles-5.png" className="absolute -bottom-50p -right-64 md:right-0"/>
      <Container>
        <div data-section="about" className="my-24 md:my-64 md:flex md:justify-center md:items-center">
          <div className="w-full md:w-8/12 md:flex md:flex-row-reverse">
            <div className="md:flex-1 md:pl-8 z-50">
              <h2 className="mb-4 text-2xl font-secondary wght-700">{title}</h2>
              <div
                className="mb-8 contains-html"
                dangerouslySetInnerHTML={{ __html: text[language] }}
              />
            </div>
            <div className="pt-12 -mt-2 text-right md:pt-0 md:text-left md:flex-1 md:pr-8 z-50">
              {items.map(item => (
                <React.Fragment key={item.title[language]}>
                  <h3 className="mb-2 text-5xl font-secondary wght-700">{item.title[language]}</h3>
                  <div
                    className="mb-4 contains-html"
                    dangerouslySetInnerHTML={{ __html: item.text[language] }}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
    </>
  )
}

About.propTypes = {
  language: PropTypes.string.isRequired,
  items: PropTypes.array,
  title: PropTypes.string,
  text: PropTypes.shape(),
  cta: PropTypes.shape(),
}

About.defaultProps = {
}

export default About
