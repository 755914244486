import React from "react"
import PropTypes from "prop-types"

const CImage = (props) => {
  return (
    <img
      {...props}
    />
  )
}

CImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

CImage.defaultProps = {
}

export default CImage
