import React from "react"

const Arrowdown = (props) => (
    // eslint-disable-next-line react/prop-types
    <svg className={`inline-block stroke-current ${props.className}`} width="18" height="8" xmlns="http://www.w3.org/2000/svg">
        <path
        d="M17 0l-6.192 6.192a2.556 2.556 0 01-3.616 0L1 0"
        stroke="#CEDFE3"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="stroke-current"
        />
    </svg>
)

export default Arrowdown