const archiveItems = require("./archiveItems");

const data = {
  miniLogoImage: {
    alt: "Formentera22",
    src: "/f22.png",
  },
  miniLogoOpenImage: {
    alt: "Formentera22",
    src: "/formentera20.svg",
  },
  items: [
    {
      id: "1",
      title: {
        es: "Sobre el evento",
        ca: "Sobre l'esdeveniment",
        en: "About",
      },
      anchorTo: "about",
    },
    {
      id: "2",
      title: {
        es: "Ponentes",
        ca: "Ponents",
        en: "Speakers",
      },
      anchorTo: "speakers",
    },
    {
      id: "3",
      title: {
        es: "Programa",
        ca: "Programa",
        en: "Program",
      },
      hrefTo: {
        es: "programa",
        ca: "programa-cat",
        en: "program",
      },
    },
    {
      id: "4",
      title: {
        es: "Sponsors",
        ca: "Sponsors",
        en: "Sponsors",
      },
      hrefTo: {
        es: "sponsors",
        ca: "sponsors",
        en: "sponsors",
      },
    },
    {
      id: "5",
      title: {
        es: "Archivo",
        en: "Archive",
        ca: "Arxiu",
      },
      items: archiveItems,
    },
  ],
  text1: {
    es: `Cultura digital<br>Comunicación<br>Creatividad`,
    ca: `Cultura digital<br>Comunicació<br>Creativitat`,
    en: `Digital culture<br>Communication<br>Creativity`,
  },
  textSeparatorImage: {
    src: "/wave.svg",
    alt: "",
  },
  text2: {
    es: `Del 16 al 19<br/>de mayo de 2024.`,
    ca: `Del 16 al 19<br/>de maig de 2022.`,
    en: `16th to 19th<br/>of May of 2024.`,
  },
  sponsors: [
    {
      href: "#",
      image: {
        alt: "Formentera – Illes Balears",
        src: "/sponsors/formentera-black.png",
        srcblack: "/sponsors/formentera22.png",
      },
    },
    {
      href: "#",
      image: {
        alt: "Formentera – Illes Balears",
        src: "/rc.svg",
      },
    },
  ],
};
export default data;
