const data = {
  name: "Footer",
  props: {
    copyright: "© Formentera20 2024",
    newsletter: {
      href: "//formentera.dallonses.com/index.php?email=",
      title: {
        es: "Apúntate a la newsletter",
        ca: "Apúnta't a la newsletter",
        en: "Sign up in our newsletter",
      },
      placeholder: {
        es: "Tu email",
        ca: "El teu email",
        en: "Your email",
      },
      cta: {
        title: {
          es: "Enviar",
          ca: "Enviar",
          en: "Send",
        },
      },
      ok: {
        es: "Gracias!",
        ca: "Gracies!",
        en: "Thank you!",
      },
      nok: {
        es: "Error. Inténtalo de nuevo.",
        ca: "Error. Torna a provar-ho.",
        en: "Error. Try again.",
      },
    },
    socialNetworks: [
      {
        image: "/social-media/twitter.svg",
        title: "Twitter",
        url: "https://twitter.com/formentera20",
      },
      {
        image: "/social-media/facebook.svg",
        title: "Facebook",
        url: "https://www.facebook.com/formentera20/",
      },
      {
        image: "/social-media/instagram.svg",
        title: "Instagram",
        url: "https://www.instagram.com/formentera2.0/",
      },
      {
        image: "/social-media/youtube.svg",
        title: "YouTube",
        url: "https://www.youtube.com/channel/UCYxqFXXJ0lW1NwiX5aJasBA",
      },

      {
        image: "/sponsors/tiktok.svg",
        title: "Tiktok",
        url: "https://tiktok.com/@formentera20oficial?_t=8hF2eHo70Hx&_r=1",
      },
    ],
    organizes: {
      title: {
        es: "Idea/Dirección:",
        ca: "Idea/Direcció:",
        en: "Idea/Direction:",
      },
      image: "/rc.svg",
      cta: {
        title: "Rosa Castells",
        href: "https://rosacastells.net/",
      },
    },
    submenu: [
      {
        title: {
          es: "Contacto",
          ca: "Contacte",
          en: "Contact",
        },
        href: "mailto:info@formentera20.com",
      },
    ],
  },
};
export default data;
