import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Cta from "./Cta";

const clip = {
  path: `M148.26922,357 C230.15605,357 330,255.665897 330,174.702128 C330,93.7383584 230.15605,0 148.26922,0 C66.3823911,0 0,89.9404861 0,170.904255 C0,251.868025 66.3823911,357 148.26922,357 Z`,
  w: 330,
  h: 357,
};

const cta = {
  title: {
    es: "Apúntate",
    ca: "Apunta't",
    en: "Sign up",
  },
  target: "_blank",
  href:
    "https://ovac.conselldeformentera.cat/ovac/castellano/Tramites/1A4F1D6839DF4197898A920FE30B4014.asp",
};

const Speaker = ({ language, name, image, role, url, text }) => {
  console.log(name, "speakername");
  useEffect(() => {
    dispatchEvent(
      new CustomEvent("indicator", { detail: { id: "4", state: true } })
    );
  });

  const Bubble = ({ svgName }) => (
    <>
      <div
        className={`relative md:absolute`}
        style={{
          maxWidth: clip.w,
          maxHeight: clip.h,
          // transform: 'scale(0.8)',
        }}
      >
        <Cta
          title={{ en: name, es: name, ca: name }}
          language={language}
          href={cta.href}
          target="_blank"
          svgName={svgName}
          className={`absolute text-white bottom-0 z-10 bg-e22-gray scale08 md:scale1 right-0 md:right-0`}
        >
          <span className="block text-sm font-bold text-center">
            {cta.title[language]}
          </span>
        </Cta>
        <div
          className={`overflow-hidden relative scale08 md:scale1`}
          style={{
            height: clip.h,
            width: clip.w,
            clipPath: `url(#clipSpeaker)`,
            WebkitClipPath: `url(#clipSpeaker)`,
          }}
        >
          <div
            className="absolute top-0 bottom-0 left-0 right-0 bg-center bg-no-repeat bg-cover hover:scale11 t-transform"
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
          <div className="absolute top-0 bottom-0 left-0 right-0" />
        </div>
      </div>
    </>
  );

  return (
    <div className="my-24">
      <div className="absolute bottom-0 left-0 w-0 h-0 overflow-hidden pointer-events-none -z-10">
        <svg>
          <defs>
            <clipPath id={`clipSpeaker`}>
              <path d={clip.path} />
            </clipPath>
          </defs>
        </svg>
      </div>
      <Container className="justify-center md:flex">
        <div className="flex md:w-8/12">
          <div className="w-full md:w-2/3">
            <h1 className="p-0 m-0 mb-8 text-3xl leading-none text-e22-gray md:text-5xl font-secondary wght-700">
              {name}
            </h1>
            <h5 className="text-e22-gray">{role[language]}</h5>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="pb-1 text-e22-gray border-b border-e22-gray border-solid"
              href={url}
            >
              {url.replace("http://", "").replace("https://", "")}
            </a>
            <img className="my-12" src="/wave.svg" />
            <div className="relative block w-full overflow-hidden md:hidden">
              <Bubble />
              <img className="my-12" src="/wave.svg" />
            </div>

            <div
              className="text-e22-gray md:pr-48 contains-html"
              dangerouslySetInnerHTML={{ __html: text[language] }}
            />
            <img className="my-12" src="/wave.svg" />
          </div>
          <div className="relative hidden w-full md:block md:w-1/3">
            <Bubble svgName="mdcta" />
          </div>
        </div>
      </Container>
    </div>
  );
};

Speaker.propTypes = {
  svgName: PropTypes.string,
  language: PropTypes.string.isRequired,
  name: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  role: PropTypes.shape().isRequired,
  text: PropTypes.shape().isRequired,
};

Speaker.defaultProps = {};

export default Speaker;
