const data = {
  title: {
    es: "Formentera20: Cultura digital. Nuevas tecnologías. Comunicación",
    ca: "Formentera20: Cultura digital. Noves tecnologíes. Comunicació",
    en: "Formentera20: Digital culture. New technologies. Communication",
  },
  description: {
    es: `11ª edición: 16 – 19 de mayo de 2024. Transformación digital. Social media. Marketing`,
    ca: `11a edició: 16 – 19 de maig de 2024. Transformació digital. Social media. Marketing`,
    en: `11th edition: 16th – 19th May of 2024. Digital transformation. Social media. Marketing`,
  },
  keywords: "formentera, digital, tecnologia, social media, marketing",
  favicon: "/favicon.png",
};
export default data;
