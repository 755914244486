import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import PropTypes from "prop-types"

const CLink = (props) => {
  if (props.to.length > 0 && props.to.charAt(0) !== '/') {
    return (
      <a
        href={props.to}
        {...props}
      >
        {props.children}
      </a>
    )
  }
  return (
    <TransitionLink
      {...props}
      exit={{
        length: 0.6,
        delay: 0,
      }}
      entry={{
        length: 0.6,
        delay: 0.6,
      }}
    >{props.children}</TransitionLink>
  )
}

CLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
}

CLink.defaultProps = {
  children: null,
  to: '',
}

export default CLink
