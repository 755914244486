import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"

const FixedBubbles = ({
  className,
}) => {

    const fixedBall = useRef(null)
    const ref = useRef(null)

    function animate(timestamp) {
        if (!timestamp || !fixedBall.current) return requestAnimationFrame(animate)

        const transformX = Math.cos(1.32 * timestamp * 0.001 * Math.sin(0.92)) * 8
        const transformY = Math.sin(1.32 * timestamp * 0.001 * Math.cos(0.92)) * 8

        fixedBall.current.style.transform =  `translate(${transformX}px, ${transformY}px)`
        requestAnimationFrame(animate)
    }

    useEffect(() => {
    ref.current = requestAnimationFrame(animate)

    return () => {
        if (ref.current) {
        cancelAnimationFrame(ref.current)
        ref.current = null
        }
    }
    }, [])

    animate()

  return (
    <div
      className={`${className}`}
      ref={fixedBall}
    >
        <div className="w-full transition flex justify-center"  >
          <img src="/bubbles/fixedBubbleTop.png" />
        </div>  
        <div className="w-full transition"  >
            <img src="/bubbles/fixedYellowOval_0.png" />
        </div>   
        <div className="w-full flex justify-center transition" >
            <img src="/bubbles/fixedWhiteOval.png" />
        </div>   
        <div className="w-full transition -mt-12" >
            <img src="/bubbles/fixedBlueOval.png"  />
        </div>   
        <div className="w-90p flex justify-end transition mt-12" >
            <img src="/bubbles/fixedYellowOval_1.png" className="w-90%"/>
        </div>   
    </div>
  )
}

FixedBubbles.propTypes = {
  className: PropTypes.string,
}

FixedBubbles.defaultProps = {
  className: '',
}


export default FixedBubbles