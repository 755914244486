import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Menu from './Menu'
import Bubbles from './Bubbles'

const Layout = ({
  name,
  children,
  menu,
  language,
}) => {

  const [bootstrap, setBootstrap] = useState(false)
  const [mobile, setMobile] = useState(false)
  
  useEffect(() => {
    if (window.YT) {
      setBootstrap(true)
    }
    const tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    window.onYouTubeIframeAPIReady = () => {
      setBootstrap(true)
    }
  }, []) 

  const home = name === "index" 

  useEffect(() => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    setMobile(width < 769)
  }, [])

  
  return (
    <>
      {!bootstrap ? null : (
        <div
          className="relative bg-e22-beigeLight text-w22-gray font-primary"
        >
        <Menu {...menu} name={name} language={language} />
        <div
          className="relative z-10 pt-20 md:pt-0"
        >
          {children}
        </div>
        <Menu name={name} {...menu} language={language} sticky />
        { !home && <Bubbles name={name} /> }
        { mobile && <Bubbles name={name} /> }

      </div>
      )}
    </>
  )
}

Layout.propTypes = {
  name: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  children: PropTypes.node,
  menu: PropTypes.shape(),
}

Layout.defaultProps = {
  children: null,
  menu: {},
}

export default Layout
