import React from "react"
import PropTypes from "prop-types"
import Container from './Container'
import YouTubePlayer from './YouTubePlayer'
import Vimeo from './Vimeo'

const Archive = ({
  language, videos
}) => {

  function handleSize(id) {
    if (id === "1"){
      return "md:w-full"

    } else if (id % 2 == 0){
      return "md:w-1/2 md:pr-6"

    } else {
      return "md:w-1/2 md:pl-6"
    }
  }

  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  const isMobile = width < 769

  return (
    <>
    {videos ? (
      <Container>
        <div className="w-full pb-32 mt-24 md:mt-0 md:pb-32 md:flex md:justify-center">
          <div className="flex flex-wrap w-full md:w-11/12">
              <>
                {videos.map((video, index) => (
                    <div 
                      key={video.id}
                      className={index === 0 ? 'w-full my-8' : `my-8 md:my-12 relative w-full ${handleSize(video.id)}`}
                    >
                      {video.vimeo ? (
                        <Vimeo
                          language={language}
                          id={video.videoId}
                          pageName="archive"
                          smallerCta={isMobile ? (index >= 0) : (index !== 0) }
                        />
                      ) : (
                        <YouTubePlayer language={language} id={video.videoId}/>
                      )}
                    </div>
                ))}
              </>
          </div>
        </div>
      </Container>
    ) : ""}
    </>
  )
}

Archive.propTypes = {
  language: PropTypes.string,
  videos: PropTypes.array,
  cta: PropTypes.shape()
}

Archive.defaultProps = {
}

export default Archive