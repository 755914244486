import React from 'react'
import PropTypes from 'prop-types'
import Wrapper from './Wrapper'
import FixedBubbles from './FixedBubbles'

const types = {}

function importAll(r) {
  r.keys().forEach((key) => {
    const filename = key.match(/([a-zA-Z0-9]+)\.js$/)
    if (filename) {
      types[filename[1]] = r(key).default
    }
  })
}

importAll(require.context('./', true))

const ComponentIterator = ({
  pageName,
  components,
  language,
}) => {

  let keyIndex = 0
  const home = pageName === "index"

  return (
    <div className="h-max relative">
      {home &&
        <FixedBubbles className="h-max w-full absolute top-0 z-0 hidden xl:block" />   
      }
      {
        components.map((component, index) => {
          if (types[component.name]) {
            const ComponentType = types[component.name]
            const iterator = {
              isFirst: component.name !== 'Wrapper' && index === 0,
              isLast: component.name !== 'Wrapper' && index === components.length - 1,
            }
            return (
              <Wrapper
                key={`${component.name}_${keyIndex++}`}
                pageName={pageName}
                name={component.name}
                iterator={iterator}
              >
                <ComponentType
                  {...component.props}
                  language={language}
                  >
                  { component.components &&
                  <ComponentIterator
                    key={`${component.name}_${keyIndex++}`}
                    pageName={pageName}
                    components={component.components}
                    language={language}
                  /> }
                </ComponentType>
              </Wrapper>
            )
          }
        })
      }
    </div>
  )
}

ComponentIterator.propTypes = {
  pageName: PropTypes.string,
  language: PropTypes.string.isRequired,
  components: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
}

ComponentIterator.defaultProps = {
  components: [],
  pageName: '',
}

export default ComponentIterator