import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Container from './Container'

const Footer = ({
  language,
  copyright,
  newsletter,
  socialNetworks,
  submenu,
  organizes,
}) => {
  const [status, setStatus] = useState(null)
  const [email, setEmail] = useState("")

  const subscribe = async () => {
    if (email.length > 0) {
      const response = await fetch('https://45t5e4hk39.execute-api.eu-west-3.amazonaws.com/default/formentera20-newsletter', {
        method: "POST",
        body: JSON.stringify({ email_address: email, status: 'subscribed' })
      })

      if (response.status === 200) {
        setEmail('')
        setStatus(true)
      } else {
        setStatus(false)
      }
    }
  }

  useEffect(() => {
    let timeoutId

    timeoutId = setTimeout(() => {
      setStatus(null)
    }, 3000)
  
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [status])

  return (
    <>
      <div className="relative overflow-hidden">
        <div className="absolute inset-x-0 top-0 flex items-start justify-center -mx-48 pointer-events-none">
          <img src="/bubbles/fixedBubbleBottom.svg" />
        </div>
        <Container className="relative">
          <div className="pb-12 mt-24 text-e22-gray md:pt-40">
            <div className="text-center md:flex md:text-left">
              <div className="hidden md:block md:w-1/12"></div>
              <div className="hidden md:block md:w-2/12">
                <div className="font-logoFont text-logo md:text-logo-footer logo t-wght2 hover:logo-ready">
                  F
                </div>
              </div>
              <div className="flex flex-col items-center mb-12 md:w-4/12 md:items-start md:mb-0">
                <span className="text-xl text-e22-gray font-secondary wght-700 md:font-normal">
                  {newsletter.title[language]}
                </span>
                <input
                  className="w-full my-6 text-center placeholder-e22-gray bg-transparent border-b border-solid outline-none md:text-left"
                  type="text"
                  placeholder={newsletter.placeholder[language]}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span
                  className="relative"
                  onClick={() => {
                    if (status === null || status === false) {
                      subscribe()
                    }
                  }}
                >
                  <img src="/newsletter_cta.svg" />
                  <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center cursor-pointer cta t-wght hover:cta-ready">
                    <span className="text-center block text-white font-secondary px-4+">
                      {status === null && newsletter.cta.title[language]}
                      {status === true && newsletter.ok[language]}
                      {status === false && newsletter.nok[language]}
                    </span>
                  </div>
                </span>
              </div>
              <div className="hidden md:block md:w-2/12"></div>
              <div className="w-full md:w-3/12">
                <div className="flex justify-center md:justify-start">
                  {socialNetworks.map((sn) => (
                    <a
                      key={sn.title}
                      href={sn.url}
                      title={sn.title}
                      className="mr-2 t-transform hover:scale09"
                    >
                      <img src={sn.image} alt={sn.title} />
                    </a>
                  ))}
                </div>
                <div className="mt-6 mb-4">
                  <span className="flex justify-center text-e22-gray md:block">
                    <a
                      className="inline mx-2 underline md:ml-0 md:mr-2"
                      href="/es"
                    >
                      ES
                    </a>
                    <a
                      className="inline mx-2 underline md:ml-0 md:mr-2"
                      href="/ca"
                    >
                      CA
                    </a>
                    <a
                      className="inline mx-2 underline md:ml-0 md:mr-2"
                      href="/en"
                    >
                      EN
                    </a>
                  </span>
                  {submenu.map((item) => (
                    <a
                      key={item.title}
                      href={item.href}
                      className="text-e22-gray underline"
                    >
                      {item.title[language]}
                    </a>
                  ))}
                </div>
                <div className="flex items-center justify-center my-4 text-e22-gray md:justify-start">
                  {organizes.title[language]}
                  <img className="w-12 mx-4" src={organizes.image} />
                  <a className="underline" {...organizes.cta}>
                    {organizes.cta.title}
                  </a>
                </div>
              </div>
            </div>
            <div className="text-center md:text-left md:flex md:pt-8">
              <div className="hidden md:block md:w-1/12"></div>
              <span className="font-bold">{copyright}</span>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

Footer.propTypes = {
  language: PropTypes.string.isRequired,
  copyright: PropTypes.string,
  newsletter: PropTypes.shape(),
  socialNetworks: PropTypes.array,
  submenu: PropTypes.array,
  organizes: PropTypes.shape(),
}

Footer.defaultProps = {}

export default Footer
