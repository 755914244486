import React from "react"
import PropTypes from "prop-types"
import Container from './Container'
import Cta from './Cta'

const cta = {
  title: {
    es: 'Ver más',
    ca: 'Veure més',
    en: 'Find out more',
  },
  href: '#',
}

const Media = ({
  language, media
}) => {
  return (
    <>
    {media ? (
      <Container>
        <div className="text-e22-gray w-full pt-10 md:pt-0 md:mb-3.5rem md:flex md:justify-center">
          <div className="w-full md:justify-start md:w-8/12 md:flex md:flex-wrap">
            <h2 className="w-full mb-16 text-3xl wght-700 font-secondary md:text-5xl md:mb-4.5rem">{media.title[language]}</h2>
            {media.items.map(item => (
                <div 
                  key={item.id}
                  className={`w-full my-8 md:w-1/2 ${item.id % 2 == 0 ? "md:pl-6" : "md:pr-6" }`}
                >
                  <div className="mb-12 font-bold">
                    <div className="relative mb-6">
                      <div className="absolute bottom-0 right-0 p-5">
                        <Cta 
                          language={language} {...cta}
                          svgName={item.id}
                          className={`bg-turquoise text-blue-dark transform scale07 md:scale08`}
                          href={item.url}
                          target="_blank"
                        />
                      </div>
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-full h-auto"
                      >
                        <img className="w-full h-full" src={item.image} />
                      </a>
                    </div>
                    {item.title[language]}
                  </div>
                </div>
            ))}
          </div>
        </div>
      </Container>
    ) : ""}
    </>
  )
}

Media.propTypes = {
  language: PropTypes.string,
  media: PropTypes.object
}

Media.defaultProps = {
}

export default Media