import React, { useState, useEffect }  from "react"
import PropTypes from "prop-types"
import Cta from './Cta'

const cta = {
  title: {
    es: 'Play',
    ca: 'Play',
    en: 'Play',
  },
  href: '#',
}

const YouTubePlayer = ({
  language, id
}) => {

  const [playing, setPlaying] = useState(false)
  const [player, setPlayer] = useState({})
  const [videoSource, setVideoSource] = useState(`https://www.youtube.com/embed/${id}`)

  const togglePlaying = (e) => {
    e.preventDefault()
    if (!playing) {
      setVideoSource(`https://www.youtube.com/embed/${id}?autoplay=1`)
      setTimeout(() => {
        setPlaying(true)
        // player.playVideo()
      }, 200)
    }
  }

  const loadVideo = () => {
    setPlayer(
      new window.YT.Player(`youtube-player-${id}`, {
        videoId: id,
        rel: 0,
        showinfo: 0,
        modestbranding: 1
      })
    )
  }

  useEffect(() => {
    loadVideo()
  }, [])

  return (
    <div
      className="relative w-full h-0"
      style={{ paddingTop: '56.25%' }}
    >
      <iframe
        id={`youtube-player-${id}`}
        className="absolute top-0 left-0 w-full h-full"
        src={videoSource}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      {/* <div className={`w-full h-full absolute top-0 left-0`} id={`youtube-player-${id}`} /> */}
      <div
        className={`absolute top-0 left-0 p-5 flex items-end justify-end w-full h-full ${playing ? "hidden" : "block"}`}
      >
        <Cta
          language={language} {...cta}
          svgName={id}
          className={`bg-turquoise text-blue-dark transform scale07 absolute right-0 bottom-0`}
          callback={(e) => togglePlaying(e)}
        />
      </div>
    </div>
  )
}

YouTubePlayer.propTypes = {
  language: PropTypes.string,
  id: PropTypes.string
}

export default YouTubePlayer