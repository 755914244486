import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useSpring, animated } from 'react-spring'
import CLink from './CLink'
import CImage from './CImage'
import MenuPrimaryLink from './MenuPrimaryLink'
import Container from './Container'
import { Link, navigate } from "gatsby"


const animationDuration = 200
let scrollPosition

const Menu = ({
  name,
  language,
  sticky,
  miniLogoImage,
  miniLogoOpenImage,
  items,
  text1,
  textSeparatorImage,
  text2,
  sponsors,
}) => {

  const [open, setOpen] = useState(false)
  const [hasScrolled, setHasScrolled] = useState(false)
  const [activeItem, setActiveItem] = useState(-1)
  const [isMobile, setIsMobile] = useState()

  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  // const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

  useEffect(() => {
    const mobile = width < 768
    setIsMobile(mobile)

    if (sticky) {
      document.addEventListener('scroll', handleScroll)
      return () => {
        document.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    if (window.location.hash) {
      const anchorTo = window.location.hash.replace('#', '')
      const el = document.querySelector(`[data-section="${anchorTo}"]`)
      if (el) {
        setOpen(false)
        window.scrollTo({
          top: el.getBoundingClientRect().top + window.scrollY - 100,
          behavior: 'smooth',
        })
      }
    }
  }, [])

  const handleScroll = () => {
    const height = window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight
    // console.log(window.scrollY, height/2, hasScrolled)
    if (window.scrollY > height/2 && !hasScrolled) {
      setHasScrolled(true)
    } else if (window.scrollY < height/2 && hasScrolled) {
      setHasScrolled(false)
    }
  }

  // Handle indicator
  const [indicator, setIndicator] = useState()
  
  useEffect(() => {
    const handleIntersection = function(entries) {
      if (entries[0].isIntersecting === true) {
        let currentSection = entries[0].target.attributes["data-el"].value
        setIndicator(currentSection)
      }
    }
    
    const observerOptions = {root: null, rootMargin: "0px", threshold: 0.1}
    const observer = new IntersectionObserver(handleIntersection, observerOptions)
    
    const elements = document.querySelectorAll("[data-el]")
    elements.forEach(element => observer.observe(element))
    
    // limpiar al desmontar el componente
    return() => observer.disconnect()
  }, [])

  const topArrowProps = useSpring({
    transform: open ? `rotate(-45deg)` : `rotate(0deg)`,
    marginBottom: open ? -1 : 4,
    config: {
      duration: animationDuration / 2,
    },
  })
  const bottomArrowProps = useSpring({
    transform: open ? `rotate(45deg)` : `rotate(0deg)`,
    marginTop: open ? -1 : 4,
    config: {
      duration: animationDuration / 2,
    },
  })
  const menuItemsProps = useSpring({
    transform: open ? `translateY(0)` : `translateX(-100%)`,
    display: open ? `block` : `none`,
    delay: (key) => {
      if (!open && key === 'display') {
        return animationDuration / 2
      }
      return 0
    },
    config: {
      duration: animationDuration / 2,
    },
  })
  const stickyProps = useSpring({
    transform: hasScrolled ? `translateY(0)` : `translateY(-100%)`,
    config: {
      duration: animationDuration,
    },
  })

  if (sticky && isMobile) {
    return null
  }

  return (
    <animated.div
      style={sticky ? stickyProps : null}
      className={`fixed ${sticky ? '' : 'md:block md:relative'} top-0 left-0 right-0 w-full z-50 t-bg ${open ? 'h-full md:h-auto bg-e22-gray' : ''}`}
    >
      <div
        className={`${open ? 'bg-e22-gray h-20' : 'bg-white md:bg-transparent h-20'} t-bg flex justify-between z-50 relative md:hidden`}
      >
        <div
          className="flex flex-col items-center justify-center flex-shrink-0 w-16 md:hidden"
          onClick={() => {
            if (!open) {
              scrollPosition = window.scrollY
            } else {
              window.scrollTo(0, scrollPosition)
            }
            setOpen(!open)
          }}
        >
          <animated.span
            style={topArrowProps}
            className={`h-2px w-6 inline-block ${open ? 'bg-white' : 'bg-e22-gray'}`}
          />
          <animated.span
            style={bottomArrowProps}
            className={`h-2px w-6 inline-block ${open ? 'bg-white' : 'bg-e22-gray'}`}
          />
        </div>
        <div className="px-4 py-6 bg-transparent md:flex-auto md:px-0 md:flex md:items-center">
          <CLink
            to="/"
          >
            <CImage
              className={`h-28 md:h-10 block md:mx-auto ${open ? 'block' : 'hidden'}`}
              {...miniLogoOpenImage}
            />
            <CImage
              className={`h-8 md:h-10 block md:mx-auto ${open ? 'hidden' : 'block'}`}
              {...miniLogoImage}
            />
          </CLink>
        </div>
      </div>
      <animated.div
        style={menuItemsProps}
        className={`bg-e22-gray ${sticky ? 'md:bg-white' : 'md:bg-transparent'} overflow-scroll hidden md:block-important md:transform-none md:overflow-visible h-full z-0 pb-24 md:pb-0`}
      >

        <nav
          className="mt-24 md:mt-0"
        >
          <Container className="items-center justify-between md:flex md:h-24">
            <Link
              to={`/${language}`}
            >
              <div className="w-24">
                <CImage
                  className={`w-20 hidden md:block`}
                  {...miniLogoImage}
                />
              </div>
            </Link>
            <div className="relative md:flex">
              {items && items.map(item => <React.Fragment key={item.id}>
                <MenuPrimaryLink
                  {...item}
                  language={language}
                  onClickCallback={(id, anchorTo) => {
                    setActiveItem(activeItem === id ? -1 : id)
                    if (anchorTo) {
                      if (name === 'index') {
                        const el = document.querySelector(`[data-section="${anchorTo}"]`)
                        if (el) {
                          setOpen(false)
                          window.scrollTo({
                            top: el.getBoundingClientRect().top + window.scrollY - 100,
                            behavior: 'smooth',
                          })
                        }
                      } else {
                        navigate(`/${language}#${anchorTo}`)
                      }
                    }
                  }}
                  active={activeItem === item.id}
                  onView={indicator === item.id}
                />
                {item.items && <div className={`${activeItem === item.id ? 'md:absolute md:right-0 md:mt-8 md:bg-e22-gray' : 'hidden'}`}>
                  {item.items.map((subitem, si) => (
                    <MenuPrimaryLink
                      key={si}
                      language={language}
                      subitem={true}
                      last={si === item.items.length - 1}
                      {...subitem}
                    />
                  )
                  )}
                </div>}
              </React.Fragment>)}
            </div>
            <img
              src={ sponsors[0].image.srcblack }
              alt={sponsors[0].image.alt}
              className="hidden w-24 md:block"
            />
          </Container>
        </nav>
        <div className="flex flex-col justify-between md:hidden">
          <div className="flex flex-col items-center mt-16 text-center md:hidden">
            <div
              className="w-2/4 text-white contains-html"
              dangerouslySetInnerHTML={{ __html: text1[language] }}
            />
            <CImage
              className="my-8"
              {...textSeparatorImage}
              />
            <div
              className="w-2/4 text-white contains-html"
              dangerouslySetInnerHTML={{ __html: text2[language] }}
            />
          </div>
          <div className="flex flex-row items-center justify-center mt-16 mb-2 md:hidden">
            <div className="flex items-center">
            {sponsors.map((sponsor, i) => (
              <a
                key={sponsor.image.src}
                title={sponsor.image.alt}
                href={sponsor.href}
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                className={`${i === 0 ? 'w-20' : 'w-12'} mx-4`} rel="noreferrer"
              >
              <CImage
                {...sponsor.image}
                />
              </a>
            ))}
            </div>
          </div>
        </div>
      </animated.div>
    </animated.div>
  )
}

Menu.propTypes = {
  name: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  children: PropTypes.node,
  sticky: PropTypes.bool,
  miniLogoImage: PropTypes.shape(),
  miniLogoOpenImage: PropTypes.shape(),
  items: PropTypes.array,
  text1: PropTypes.shape(),
  textSeparatorImage: PropTypes.shape(),
  text2: PropTypes.shape(),
  sponsors: PropTypes.array,
  organizes: PropTypes.shape(),
}

Menu.defaultProps = {
  children: null,
  sticky: false,
}

export default Menu
