const clips = [
  {
    path: `M140.704 239.305C246.232 325.28 333 247.434 333 137.165 333 26.899 217.269 0 151.648 0 86.028 0 0 42.287 0 107.506c0 65.218 35.176 45.824 140.704 131.8z`,
    w: 333,
    h: 316,
  },
  {
    path: `M170.630435,308.931042 C232.343099,354.930306 334,247.608181 334,169.722745 C334,91.8373085 294.065217,16.5936178 174.26087,1.46228116 C54.4565217,-13.6690555 0,91.8373085 0,169.722745 C0,247.608181 108.917771,262.931779 170.630435,308.931042 Z`,
    w: 334,
    h: 320,
  },
  {
    path: `M148.26922,357 C230.15605,357 330,255.665897 330,174.702128 C330,93.7383584 230.15605,0 148.26922,0 C66.3823911,0 0,89.9404861 0,170.904255 C0,251.868025 66.3823911,357 148.26922,357 Z`,
    w: 330,
    h: 357,
  },
  {
    path: `M59.9550164,267.96663 C142.717966,327.998115 310,251.436221 310,140.508476 C310,29.5807318 261.969276,0 194.122341,0 C126.275406,0 86.0860275,0 38.1666949,69.601722 C-9.75263777,139.202947 -22.8079332,207.935144 59.9550164,267.96663 Z`,
    w: 310,
    h: 289,
  },
  {
    path: `M77.6151341,287.10215 C177.492275,350.124573 308.918574,273.097167 330.826379,176.871312 C352.734185,80.6454577 256.851591,0 177.492275,0 C98.1329596,0 28.5424711,60.412201 12.7713153,106.787995 C-2.99984047,153.163789 -22.2620073,224.079727 77.6151341,287.10215 Z`,
    w: 334,
    h: 310,
  },
  {
    path: `M129.562229,308.439329 C224.561535,347.824691 302.294374,279.620771 328.20532,208.534996 C354.116266,137.44922 290.778398,24.0962268 185.215283,3.10101219 C79.6521683,-17.8942025 0,72.1271563 0,140.331076 C0,208.534996 34.5629232,269.053967 129.562229,308.439329 Z`,
    w: 334,
    h: 320,
  },
  {
    path: `M64.8755376,243.190563 C108.251862,271.124495 302.183259,328.850528 329.191268,209.935882 C356.199277,91.0212367 264.979681,0 167.145278,0 C69.310874,0 23.6947285,0 5.10447546,95.5397803 C-13.4857776,191.079561 21.4992128,215.256631 64.8755376,243.190563 Z`,
    w: 334,
    h: 282,
  },
  {
    path: `M143.869997,252 C291.818467,252 333,162.012832 333,117.660961 C333,73.3090909 281.904394,0 197.253465,0 C112.602536,0 18.8001558,51.9272727 5.07297818,105.381818 C-8.65419948,158.836364 -4.0784736,252 143.869997,252 Z`,
    w: 333,
    h: 252,
  },
  {
    path: `M143.869997,252 C291.818467,252 333,162.012832 333,117.660961 C333,73.3090909 281.904394,0 197.253465,0 C112.602536,0 18.8001558,51.9272727 5.07297818,105.381818 C-8.65419948,158.836364 -4.0784736,252 143.869997,252 Z`,
    w: 334,
    h: 310,
  },
]

export default clips