import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ComponentIterator from "./ComponentIterator";
import Layout from "./Layout";
import { Helmet } from "react-helmet";
import meta from "../data/meta";

const Page = ({ name, data, language }) => {
  const { title, description, keywords, favicon } = { ...meta, ...data.meta };

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://s2.adform.net/banners/scripts/st/trackpoint-sync.js";
    document.head.appendChild(script1);

    const codeBeginsScript2 = document.createComment(
      " Adform Tracking Code BEGIN "
    );
    const codeEndsScript2 = document.createComment(
      " Adform Tracking Code END "
    );
    const script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.innerHTML = `
      window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
      window._adftrack.push({
          HttpHost: 'track.adform.net',
          pm: 2323376,
          divider: encodeURIComponent('|'),
          pagename: encodeURIComponent('Formentera2.0_Visitas')
      });
      (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();
    `;
    const noScript = document.createElement("no-script");
    noScript.innerHTML = `
    <p style="margin:0;padding:0;border:0;">
    <img src="https://track.adform.net/Serving/TrackPoint/?pm=2323376&ADFPageName=Formentera2.0_Visitas&ADFdivider=|" width="1" height="1" alt="" />
    </p>
    `;
    document.body.appendChild(codeBeginsScript2);
    document.body.appendChild(script2);
    document.body.appendChild(noScript);
    document.body.appendChild(codeEndsScript2);

    const codeBeginsScript3 = document.createComment(
      " Adform Tracking Code BEGIN "
    );
    const codeEndsScript3 = document.createComment(
      " Adform Tracking Code END "
    );
    const script3 = document.createElement("script");
    const noScript2 = document.createElement("no-script");
    script3.type = "text/javascript";
    script3.innerHTML = `
      window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
      window._adftrack.push({
          HttpHost: 'track.adform.net',
          pm: 2323376,
          divider: encodeURIComponent('|'),
          pagename: encodeURIComponent('TYP_ALTA')
      });
      (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();
    `;
    noScript2.innerHTML = `
      <p style="margin:0;padding:0;border:0;">
          <img src="https://track.adform.net/Serving/TrackPoint/?pm=2323376&ADFPageName=TYP_ALTA&ADFdivider=|" width="1" height="1" alt="" />
      </p>
    `;
    document.body.appendChild(codeBeginsScript3);
    document.body.appendChild(script3);
    document.body.appendChild(noScript2);
    document.body.appendChild(codeEndsScript3);

    const codeBeginsScript4 = document.createComment(
      " Adform Tracking Code BEGIN "
    );
    const codeEndsScript4 = document.createComment(
      " Adform Tracking Code END "
    );
    const script4 = document.createElement("script");
    script4.type = "text/javascript";
    const noScript4 = document.createElement("no-script");
    script4.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window,document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '758015021853199');
      fbq('track', 'PageView');
    `;
    noScript4.innerHTML = `
    <img height="1" width="1"
    src="https://www.facebook.com/tr?id=758015021853199&ev=PageView
    &noscript=1"/>
  `;
    document.body.appendChild(codeBeginsScript4);
    document.body.appendChild(script4);
    document.body.appendChild(noScript4);
    document.body.appendChild(codeEndsScript4);
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title[language]}</title>
        <meta name="description" content={description[language]} />
        <meta name="keywords" content={keywords} />
        <meta name="language" content={language} />
        <link rel="icon" type="image/png" href={favicon} />
        <meta name="author" content="Dallonses, hello@dallonses.com" />
        <meta
          name="google-site-verification"
          content="Ra5YYcgK5PmgdLtr2LAhqzESlrlRoSEX2PllE21LDPQ"
        />
        <meta
          name="canonical"
          content={`https://formentera20.com/${language}`}
        />
        <meta name="apple-touch-fullscreen" content="yes" />
        <meta name="app-mobile-web-app-capable" content="yes" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta httpEquiv="Content-Language" content={language} />
        <meta httpEquiv="Content-Script-Type" content="text/javascript" />
        <meta httpEquiv="Content-Style-Type" content="text/css" />
        <meta property="og:title" content={title[language]} />
        <meta property="og:description" content={description[language]} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://formentera20.com/${language}`}
        />
      </Helmet>
      <Layout name={name} menu={data.menu} language={language}>
        <ComponentIterator
          pageName={name}
          components={data.components}
          language={language}
        />
      </Layout>
    </div>
  );
};

Page.propTypes = {
  name: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  data: PropTypes.shape({
    menu: PropTypes.shape().isRequired,
    meta: PropTypes.shape().isRequired,
    components: PropTypes.array.isRequired,
  }),
};

export default Page;
