import React from "react"
import PropTypes from "prop-types"
import Container from './Container'

const AllSponsors = ({
  items,
}) => {
  
  return (
    <div className="relative">
      <img src="/bubbles/bubbles-9.png" className="absolute -left-64 md:right-4 md:left-0 -top-64" />
      <Container>
        <div className="mb-2 md:my-24 md:flex md:justify-center">
          <div className="full md:w-8/12">
            <div className="flex flex-col items-center mt-8 mb-8 -mx-16 md:flex-wrap md:flex-row">
              {items.map(item =>
                <a 
                  key={item.title} 
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer" 
                  className={`flex items-center justify-center p-12 w-1/2 md:w-1/4 ${item.title === "Consell Formentera" ? "px-24 md:px-12 l:px-16  lg:px-24" : "" }`}
                >
                  <img className={item.width} src={item.image} alt={item.title}/>
                </a>
                )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

AllSponsors.propTypes = {
  items: PropTypes.array
}

AllSponsors.defaultProps = {
}

export default AllSponsors

