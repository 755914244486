import React from "react"
import PropTypes from "prop-types"
// import CLink from './CLink'
import Arrowdown from '../../static/Arrowdown'
import { Link } from 'gatsby'

const MenuPrimaryLink = ({
  language,
  id,
  title,
  hrefTo,
  anchorTo,
  active,
  items,
  subitem,
  onClickCallback,
  onView,
  last,
}) => {
  const className = `text-center mb-6 md:mb-0 select-none cursor-pointer text-white md:mx-4 block border-b-2 border-solid border-transparent hover:border-e22-yellow ${onView ? "border-e22-gray" : ""} ${subitem ? `text-base text-normal md:text-white md:py-2 ${last ? '':'md:border-b md:border-white' }` : 'text-xl font-bold md:text-e22-gray'}`
  if (hrefTo) {
    return (
      <Link
        activeClassName="border-e22-yellow"
        className={className}
        to={`/${language}/${hrefTo[language]}`}
        title={title[language]}
        onClick={() => (anchorTo || items) && onClickCallback(id, anchorTo)}
        data-link={id}
      >
        {title[language]}
        {items && <div className="inline ml-2 md:text-e22-gray"><Arrowdown className={active ? 't-transform rotate-180' : 't-transform'} /></div>}
      </Link>
    )
  }
  return (
    <span
      data-link={id}
      data-anchorto={anchorTo}
      className={className}
      onClick={() => (anchorTo || items) && onClickCallback(id, anchorTo)}
    >
      {title[language]}
      {items && <div className="inline ml-2 md:text-e22-gray"><Arrowdown className={active ? 't-transform rotate-180' : 't-transform'} /></div>}
    </span>
  )
}

MenuPrimaryLink.propTypes = {
  language: PropTypes.string.isRequired,
  id: PropTypes.string,
  title: PropTypes.shape().isRequired,
  hrefTo: PropTypes.shape(),
  anchorTo: PropTypes.string,
  items: PropTypes.array,
  image: PropTypes.string,
  onClickCallback: PropTypes.func,
  onView: PropTypes.bool,
  active: PropTypes.bool,
  subitem: PropTypes.bool,
  last: PropTypes.bool,
}

MenuPrimaryLink.defaultProps = {
  onClickCallback: () => {},
  subitem: false,
  last: false,
}

export default MenuPrimaryLink
