import React, { useState } from "react"
import PropTypes from "prop-types"
import Container from './Container'
import Cta from './Cta'

const Vimeo = ({
  id,
  type,
  pageName,
  language,
  smallerCta,
}) => {
  const [playing, setPlaying] = useState(false)

  const cta = {
    title: {
      es: playing ? 'Stop' : 'Play',
      ca: playing ? 'Stop' : 'Play',
      en: playing ? 'Stop' : 'Play',
    },
    href: '#',
  }

  const togglePlaying = (e) => {
    e.preventDefault()
    setPlaying(!playing)
  }

  if (pageName === "archive") return (
    <div className="relative">
      <div className="w-full">
        <div
          className="relative w-full h-0"
          style={{ paddingTop: '56.25%' }}
        >
          <iframe
            className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
            src={`https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&sidedock=0&controls=0&autoplay=${playing ? "1" : "0"}`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
      <div
        onClick={togglePlaying}
        className={`absolute left-0 top-0 flex items-end justify-end w-full h-full ${smallerCta ? "p-5" : "pb-10 pr-5"}`}
      >
        <Cta 
          language={language} {...cta}
          svgName={id}
          className={`bg-turquoise text-blue-dark transform`}
          onClick={togglePlaying}
          smaller={smallerCta}
        />
      </div>
    </div>
  )

  return (
    <div className="relative">
      <img src="/bubbles/bubbles-3.png" className="absolute top-0 -left-64 md:left-0 md:-top-24"/>
      <img src="/bubbles/bubbles-3_half.png" className="absolute bottom-0 -right-64 md:right-0"/>
      <Container>
        <div className="md:flex md:justify-center">
          <div className={`w-full md:w-8/12 ${type === "speaker" ? "mb-24" : ""}`}>
            <div
              className="relative w-full h-0 "
              style={{ paddingTop: '56.25%' }}
            >
              <iframe
                className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
                src={`https://player.vimeo.com/video/${id}`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </Container>
  </div>
  )
}

Vimeo.propTypes = {
  parentElement: PropTypes.string,
  pageName: PropTypes.string,
  language: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  smallerCta: PropTypes.bool,
}

Vimeo.defaultProps = {
  smallerCta: false,
}

export default Vimeo