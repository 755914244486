import React from "react";
import PropTypes from "prop-types";
// import Cta from './Cta'
import clips from "../lib/clipsSchedule.js";
import Container from "./Container";
import Cta from "./Cta.js";

const Bubble = ({ item, index, clip }) => (
  <div
    className={`relative md:b${index}`}
    style={{
      maxWidth: clip.w,
      maxHeight: clip.h,
    }}
  >
    <div
      className={`overflow-hidden relative scale08 md:scale1 ${
        index % 2 === 0 ? "-right-4 md:right-0" : "-left-4 md:left-0"
      }`}
      style={{
        height: clip.h,
        width: clip.w,
        clipPath: `url(#clip_s${index})`,
        WebkitClipPath: `url(#clip_s${index})`,
      }}
    >
      <div
        className="absolute top-0 bottom-0 left-0 right-0 bg-center bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url(${item.image})`,
        }}
      />
    </div>
  </div>
);

const urlProgram = {
  es: "programa",
  ca: "programa-cat",
  en: "program",
};

const Schedule = ({
  language,
  title,
  text,
  items,
  // cta,
  seeProgram,
}) => {
  return (
    <>
      <div data-el="3" className="relative md:h-80vh">
        <img
          src="/bubbles/bubbles-7.png"
          className="absolute -top-64 -right-64 md:right-0"
        />
        <img
          src="/bubbles/bubbles-8.png"
          className="absolute top-25p -left-64 md:left-0"
        />
        <Container>
          <div
            data-section="schedule"
            className="pt-24 md:py-0 md:my-64 md:relative text-e22-gray"
          >
            <div className="md:flex flex-col md:justify-center md:absolute md:top-0 md:left-0 md:right-0 md:ml-56">
              <div className="md:w-8/12">
                <div className="md:w-1/2">
                  <h2 className="mb-4 text-2xl font-secondary wght-700 lg:text-white">
                    {title[language]}
                  </h2>
                  <div
                    className="mb-4 contains-html lg:text-white"
                    dangerouslySetInnerHTML={{ __html: text[language] }}
                  />
                </div>
              </div>
              <Cta
                language={language}
                spanClassName="w-3/4 text-center"
                className="bg-e23-lightBlue scale08 md:scale-100"
                title={seeProgram[language]}
                href={`/${language}/${urlProgram[language]}`}
                colorText="text-e22-gray"
              />
            </div>
            <div className="absolute bottom-0 right-0 w-0 h-0 overflow-hidden pointer-events-none -z-10">
              {clips.map((clip, index) => (
                <svg key={index}>
                  <defs>
                    <clipPath id={`clip_s${index}`}>
                      <path d={clip.path} />
                    </clipPath>
                  </defs>
                </svg>
              ))}
            </div>
            <div className="md:flex md:justify-center">
              <div className="md:grid md:grid-template-columns-9fr md:w-10/12 lg:w-8/12">
                <div className="md:col-start-7 md:col-end-10">
                  <Bubble item={items[0]} clip={clips[0]} index={0} />
                </div>
                <div className="relative md:row-start-2 md:col-start-4 md:col-end-10 -left-16 md:left-auto md:top-auto -top-16 scale07 md:scale1">
                  <Bubble item={items[1]} clip={clips[1]} index={1} />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

Schedule.propTypes = {
  language: PropTypes.string.isRequired,
  cta: PropTypes.shape(),
  items: PropTypes.array,
  title: PropTypes.shape(),
  text: PropTypes.shape(),
  seeProgram: PropTypes.shape(),
};

Schedule.defaultProps = {};

export default Schedule;
