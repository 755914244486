import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import { Link } from "gatsby";

const Sponsor = ({ classname, text, floatingText, url, img, alt }) => {
  return (
    <div className="relative flex items-center justify-center my-8 md:w-1/4 md:my-0 md:px-12">
      {text && <h2 className="text-xl font-secondary wght-700">{text}</h2>}
      {!text && (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img className={`m-auto ${classname}`} src={img} alt={alt} />
        </a>
      )}
      {floatingText && (
        <div className="absolute bottom-0 -mb-6 text-sm font-bold whitespace-no-wrap font-secondary">
          {floatingText}
        </div>
      )}
    </div>
  );
};

Sponsor.propTypes = {
  classname: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  img: PropTypes.string,
  alt: PropTypes.string,
};

const Sponsors = ({ language, title, items, cta }) => {
  return (
    <div data-el="4" className="relative md:mt-24 lg:mt-48">
      <Container>
        <div
          data-section="sponsors"
          className="flex flex-col py-24 text-center text-e22-gray"
        >
          <div className="flex items-center justify-center w-full m-auto lg:w-8/12 md:mb-24 whitespace-no-wrap">
            <Sponsor text={title[language]} />
          </div>
          <div className="flex flex-col w-1/2 pb-12 md:pb-24 m-auto md:flex-row justify-center items-center md:w-8/12">
            {items.map((item) => (
              <React.Fragment key={item.title}>
                <Sponsor
                  classname={item.size}
                  url={item.url}
                  img={item.image}
                  alt={item.title}
                />
              </React.Fragment>
            ))}
          </div>
          <div className="flex items-center justify-center w-full m-auto md:w-8/12">
            <Link
              className="font-bold text-e22-gray underline"
              to={`/${language}/${cta.href}`}
            >
              {cta.title[language]}
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

Sponsors.propTypes = {
  language: PropTypes.string,
  items: PropTypes.array,
  title: PropTypes.shape(),
  cta: PropTypes.shape(),
};

Sponsors.defaultProps = {};

export default Sponsors;
